import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import img_asistente from"../../../static/asistente.png"
import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import API from "../../../API";
import { PRODUCTOR } from "../../../Constantes";
import { Link } from "react-router-dom";
import useAuth from "../../../Contextos/Auth/AuthContext";
import AsistenteGuardar, { GuardarUsuario } from "../Guardar";
import ValidarRol from "../../../Componentes/ValidarRol";

const ListaUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const { user } = useAuth();
  const [cargando, setCargando] = useState(false)
    const [form] = Form.useForm()
  const navigate = useNavigate();

  const cargar = async () => {
    try {
      const { data } = await API("api/usuario/listar");

      if (user.rol == PRODUCTOR.value) {
        let filtrado = data.data;

        setUsuarios(filtrado);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const borrar = async (doc) => {
    try {
      const { data } = await API.delete(`api/usuario/${doc?._id}`);
      notification.success({ message: "usuario eliminado correctamente" });
      cargar();
    } catch (error) {
      notification.error({ message: error.toString() });
    }
  };

  const onGuardado = () => {
    setUsuario(null)
    cargar()
  }

  const cargarAsistentes = async (payload) => {
    try {
      setCargando(true)
      // console.log(payload.rol, payload.usuario)
      const { data } = await API.get(`api/usuario/listar`, { params: { busqueda: payload.usuario, rol: 3 } })

      setUsuarios(data.data)
    } catch (error) {
      notification.error({ message: error.toString() })
    } finally {
      setCargando(false)
    }
  }

  useEffect(() => {
    cargar();
  }, []);




  return (
    <ValidarRol roles={[PRODUCTOR.value]}>
      <Space size={72}>
        <Breadcrumb
          items={[{ title: <Link to="/">Inicio</Link> }, { title: "Asistentes" }]}
        />
        <Button type="link" onClick={() => setUsuario({})} icon={<PlusOutlined />}>Registrar asistente</Button>
      </Space>
      <Row>
        <Col span={24}>
          <Form layout="vertical" disabled={cargando} form={form} onFinish={cargarAsistentes}>
            <Row gutter={10}>
              <Col xs={24} md={8}>
                <Form.Item name="usuario" label="usuario">
                  <Input
                    placeholder="Busque por nombre"
                    disabled={cargando}
                    style={{
                      width: '100%',
                    }} />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item label="&nbsp;">
                  <Space size={8}>
                    <Button type="primary" style={{ backgroundColor: "#81BD00" }} loading={cargando} htmlType="submit">Filtrar</Button>
                    {/* <Button type="text" onClick={limpiar} htmlType="button" >Limpiar Filtro</Button> */}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br></br>
      <br></br>
      <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra los asistentes que deseas te colaboren en la recolección de tus datos" title="Módulo asesores" trigger='click'>
                <img width={128} src={img_asistente} />
            </Popover>
        </div>
      <AsistenteGuardar doc={usuario} setDoc={setUsuario} onGuardado={onGuardado} />
      <Table dataSource={usuarios} rowKey="_id" pagination={false} scroll={
        {
          x: 200,
          y: 200,
        }
      }>
        <Table.Column title="Nombre" dataIndex={"nombre"} />
        <Table.Column title="Apellido" dataIndex={"apellido"} />
        <Table.Column
          title="Opciones"
          render={(doc) => (
            <Space size={24}>
              <Button
                icon={<EditOutlined  />}
                shape="circle"
                onClick={() => setUsuario(doc)}
              ></Button>
              <Popconfirm
                title="Se borrará el usuario permanentemente"
                onConfirm={() => borrar(doc)}
              >
                <Button
                  shape="circle"
                  danger
                  icon={<DeleteOutlined />}
                ></Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

    </ValidarRol>
  );
};

export default ListaUsuarios;
