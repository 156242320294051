import { DeleteOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, notification, Row, Select, Typography, DatePicker, InputNumber } from "antd"
import { useForm } from "antd/es/form/Form"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TIPO_CICLOS, TIPO_ESTANQUE, TIPO_GRANJA, TIPO_INSUMOS } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"


export const CiclosGuardar = ({ ciclo, setCiclo, alGuardar }) => {
    const { base_de_datos } = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const { id } = useParams()
    const [granjas, setGranjas] = useState([])
    const [estanque, setEstanque] = useState([])
    const [insumos_, setinsumos] = useState([])
    const user = useAuth()
    const granjas_ = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_GRANJA, productor: user.user.productor } })
        setGranjas(data?.docs)
    }
    const insumo = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_INSUMOS } })
     
        setinsumos(data?.docs)
    }
    const estanques_ = async (id) => {
       
        const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE, id_granja: id, productor: user.user.productor } })
        setEstanque(data?.docs)
       
    }

    const guardar = async (payload) => {

        try {
            setCargando(true)
            let info = null
            const fecha_inicio = payload?.fecha_inicio?.toDate()
          

            if (ciclo._id) {
                info = await base_de_datos.put({ ...ciclo, ...payload, fecha_inicio })
            } else {
                const fecha = new Date();
                const anio_creacion = fecha.getFullYear();
                const productor = user.user.rol == PRODUCTOR.value ? user.user._id : user.user.productor
                info = await base_de_datos.post({
                    ...payload,
                    type: TIPO_CICLOS,
                    anio_creacion,
                    productor,
                    usuario: user.user._id,
                    fecha_inicio,
                    estado:1
                })
            }

            notification.success({ message: 'Estanque guardada' })
            setCiclo(null)
            alGuardar()

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }

    useEffect(() => {
        if (ciclo && ciclo?._id) {
            const fecha_inicio = dayjs(ciclo?.fecha_inicio)
            const fecha_final = dayjs(ciclo?.fecha_final)
            form.setFieldsValue({ ...ciclo, fecha_inicio, fecha_final })

        } else {
            form.resetFields()
        }

        granjas_()
        estanques_()
        insumo()
    }, [ciclo])

    return <Modal open={ciclo} title='Guardar ciclo productivo' closable={!cargando} onCancel={() => setCiclo(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={guardar}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Form.Item label="nombre" name='nombre_ciclo' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Form.Item label="Granja" name='granja' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select style={{ width: '100%' }} options={granjas?.map((granja, index) => ({ value: granja._id, label: granja.nombre }))} onChange={estanques_} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Estanque" name='estanque' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select style={{ width: '100%' }} options={estanque?.map((est, index) => ({ value: est._id, label: est.nombre }))} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Form.Item label="fecha inicio" name='fecha_inicio' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                {/* <Col xs={24} md={12}>
                    <Form.Item label="fecha final" name='fecha_final'  >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col> */}
            </Row>

{/* 
            <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                    <Form.Item label="cantidad de peces" name='cantidad_peses' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="Peso de venta (kg)" name='peso_de_venta' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item label="margen utilidad ponderado" name='margen_utilidad_ponderado' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row> */}
        </Form>
    </Modal>
}