import "./estilo.css"
import { Form, Input, DatePicker, Radio, Card, Row, Col, Button, Checkbox, Popconfirm, Alert, notification, Breadcrumb, Tooltip, Spin, InputNumber } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import SignatureCanvas from 'react-signature-canvas'
import { useEffect, useRef, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../Contextos/Auth/AuthContext';
import { TIPO_FORMULARIOS, TIPO_GRANJA } from '../../../Base/Contantes';
import useBaseDatos from '../../../Ganchos/BaseDatos';
import { PRODUCTOR } from '../../../Constantes';


const { Dragger } = Upload;

const CampoFirma = ({ value, onChange }) => {
    const form = Form.useFormInstance();
    const ref = useRef()

    useEffect(() => {
        if (ref?.current && value && Array.isArray(value)) {
            ref.current.fromData(value)
        }
    }, [value, ref])

    return <SignatureCanvas
        canvasProps={{
            width: 500,
            height: 200,
            style: { "border": "1px solid #000000" }
        }}
        minWidth={2}
        maxWidth={2}
        ref={ref}
        onEnd={() => {
            onChange(ref.current.toData())
        }
        } />
}

const Estanque = ({ field, index, remove }) => {
    const form = useForm()
    const forma = useWatch(['estanques',index, 'forma'], form)
 

    return <Card style={{ marginBottom: 16 }} title={`Estanque ${index + 1}`} extra={<Popconfirm title="Se borrará el estanque" onConfirm={() => remove(index)}><Button icon={<DeleteOutlined />} danger type="primary" shape="circle" /></Popconfirm>} >
        <Row gutter={[16, 16]}>
            <Col xs={24} md={4}>
                <Form.Item label="Estado" name={[field.name, 'estado_estanque']} rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={'Inactivo'}>Activo</Radio>
                        <Radio value={'Activo'}>Inactivo</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={24} md={4}>
                <Form.Item name={[field.name, 'forma']} label="Forma">
                    <Radio.Group name="radiogroup" className="block">
                        <Radio value={'Circular'}>Circular</Radio>
                        <Radio value={'Irregular'}>Irregular</Radio>
                        <Radio value={'Rectangular'}>Rectangular</Radio>
                        <Radio value={'Cuadradado'}>Cuadradado</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            {forma=='Circular' ? <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'diametro']} label="Diametro (m)" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <InputNumber />
                </Form.Item>
            </Col> : null}
            {['Rectangular','Cuadradado'].includes(forma) ? <>
            <Col xs={24} md={3}>
                <Form.Item name={[field.name, 'ancho']} label="Ancho (m)" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col xs={24} md={3}>
                <Form.Item name={[field.name, 'alto']} label="Alto (m)" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <InputNumber />
                </Form.Item>
            </Col>
            </> : null}

            <Col span={4}>
                <Form.Item name={[field.name, 'capacidad_de_siembra']} label="Capacidad de siembra">
                    <InputNumber />
                </Form.Item>
            </Col>
        </Row>
     
        <Row gutter={[16, 16]}>

            <Col xs={24} md={4}>
                <Form.Item name={[field.name, 'material']} label="Material" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Checkbox.Group style={{ width: '100%' }} >

                        <Checkbox value="Tierra">Tierra</Checkbox>

                        <Checkbox value="Cemento">Cemento</Checkbox>

                        <Checkbox value="Geomembrana">Geomembrana</Checkbox>

                        <Checkbox value="Piedra">Piedra</Checkbox>

                        <Checkbox value="Plástico">Plástico</Checkbox>

                    </Checkbox.Group>
                </Form.Item>
            </Col>
            <Col xs={24} md={4}>
                <Form.Item name={[field.name, 'sistema_aireacion']} label="Sistema de aireación" rules={[{ required: true, message: 'Campo requerido' }]} >
                    <Checkbox.Group style={{ width: '100%' }} >

                        <Checkbox value="A_chorro">A chorro</Checkbox>

                        <Checkbox value="Splash">Splash</Checkbox>

                        <Checkbox value="No_tiene">No tiene</Checkbox>

                        <Checkbox value="Ventury">Ventury</Checkbox>

                        <Checkbox value="Blower">Blower</Checkbox>

                    </Checkbox.Group>
                </Form.Item>
            </Col>
        </Row>

    </Card>
}

const CaracterizacionEspecieCultivada = ({ field, index, remove }) => {
    return <Card style={{ marginBottom: 4 }} extra={<Popconfirm title="Se borrará el estanque" onConfirm={() => remove(index)}><Button icon={<DeleteOutlined />} danger type="primary" shape="circle" /></Popconfirm>}>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'nombre_pez']} label="Especie cultivada" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'peso_final']} label="Peso final" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={'0-200'}>0-200</Radio>
                        <Radio value={'201-400'}>201-400</Radio>
                        <Radio value={'401-600'}>401-600</Radio>
                        <Radio value={'601-800'}>601-800</Radio>
                        <Radio value={'801-1000'}>801-1000</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'meses']} label="Meses de producción" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={'4'}>4</Radio>
                        <Radio value={'5'}>5</Radio>
                        <Radio value={'6'}>6</Radio>
                        <Radio value={'7'}>8</Radio>
                        <Radio value={'9'}>9</Radio>
                        <Radio value={'10'}>10</Radio>
                        <Radio value={'11'}>11</Radio>
                        <Radio value={'12'}>12</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'uso']} label="Uso" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={'Autoconsumo'}>Autoconsumo</Radio>
                        <Radio value={'Comercial'}>Comercial</Radio>
                        <Radio value={'Controlador-de_sedimento'}>Controlador de sedimento</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={10}>
                <Form.Item name={[field.name, 'valor_semilla']} label="Valor de la semilla con transporte (Pesos)" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col xs={24} md={6}>
                <Form.Item name={[field.name, 'tamaño_compra']} label="Tamaño de la compra (g)" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <InputNumber />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item name={[field.name, 'provedor_semilla']} label="Proveedor de semilla" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    </Card>
}

const TasaConversionAlimenticia = ({ field, index, remove }) => {
    return <Card style={{ marginBottom: 4 }} title='Tasa de conversión alimenticia' extra={<Popconfirm title="Se borrará la tasa de conversion" onConfirm={() => remove(index)}><Button icon={<DeleteOutlined />} danger type="primary" shape="circle" /></Popconfirm>}>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={10}>
                <Form.Item name={[field.name, 'tca']} label="TCA" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input type="number" />
                </Form.Item>
            </Col>
            <Col xs={24} md={10}>
                <Form.Item name={[field.name, 'peso']} label="Peso (g)/semanas" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input type="number" />
                </Form.Item>
            </Col>
        </Row>
    </Card>
}

export const GuardarFormulario = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [firma, setFirma] = useState(null)
    const [firmaAsesor, setFirmaAsesor] = useState(null)
    const [imageUrl, setImageUrl] = useState([]);
    const [formulario, setFormulario] = useState([]);
    const { base_de_datos } = useBaseDatos()
    const [realizarForm, setRealizarForm] = useState(false);
    const [tieneForms, setTieneForms] = useState(false);
    const [form] = useForm()
    const [granjas_p, setGranjas] = useState([])
    const [cargando, setCargando] = useState(false)


    const user = useAuth()

    const cargarGranjas = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_FORMULARIOS } })

        setGranjas(data?.docs)
    }

    const getBase64 = (archivos) => {
        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(archivos[0].originFileObj)
        })
    };

    const cargar = async () => {
        if (id && id != '') {
            setCargando(true)
            const data = await base_de_datos?.get(id)
            form.setFieldsValue(data)
            setFormulario(data)
            setCargando(false)
        }
    }

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (e) => {
            return false
        },
        async onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading' && info.fileList.length) {
                const base64 = await getBase64(info.fileList);
                const nuevos_datos = await base_de_datos.put({
                    ...formulario,
                    _attachments: {
                        ...formulario?._attachments,
                        [info.fileList[0].name]: {
                            type: info.fileList[0].type,
                            data: base64.split(",")[1]
                        }
                    }
                })
                cargar()


            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const guardar = async (payload) => {
        try {
            setCargando(true)
            //actualizar
            if (formulario && formulario?._id) {
                const formulario_creado = await base_de_datos.put({ ...formulario, ...payload })
                notification.success({ message: 'Formulario actualizado correctamente' })
                cargar()
                return
            }

            //Crear
            const fecha = new Date();
            const anio_creacion = fecha.getFullYear();
            //const productor = user.user.rol == PRODUCTOR.value ? user.user._id : user.user.productor
            const formulario_creado = await base_de_datos.post({ ...payload, fecha: new Date(), type: TIPO_FORMULARIOS, archivo: imageUrl, firma_productor: firma, firma_asesor: firmaAsesor, anio_creacion, usuario: user.user._id })
            notification.success({ message: 'Formulario creado correctamente' })
            cargar()


        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }
    }

    const rectificador = () => {
        if (formulario.length !== 0) {
            setTieneForms(true)
        } else {
            setTieneForms(false)
        }
    }

    const descargarArchivo = async (nombre_archivo) => {
        const blob = await base_de_datos.getAttachment(formulario._id, nombre_archivo)
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = nombre_archivo;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const eliminararchivo = async (nombre_archivo) => {

        // const blob = await base_de_datos.getAttachment(formulario._id,nombre_archivo)
        const eliminado = await base_de_datos.removeAttachment(formulario._id, nombre_archivo, formulario._rev).then(function (result) {
            notification.success({ message: 'eliminado correctamente' })
            cargar()
        }).catch(function (err) {
            console.log(err);
        });

    }

    const listarArchivos = () => {

        if (!formulario) {
            return
        }
        if (!formulario?._attachments) {
            return
        }

        return Object.keys(formulario?._attachments).map((k, i) => {
            return <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}><p onClick={() => descargarArchivo(k)}>{k}</p> <Link onClick={() => eliminararchivo(k)}>eliminar</Link></div>
        })
    }

    const tipo_poblacion = useWatch('tipo_poblacion', form)


    useEffect(() => {
        rectificador()
    }, [formulario])

    useEffect(() => {
        cargar()
    }, [base_de_datos])

    useEffect(() => {
        cargarGranjas()
    }, [])


    return <div>
        <Spin spinning={cargando}>

            <Breadcrumb items={
                [
                    { title: <Link to='/' >inicio</Link> },
                    { title: <Link to='/formularios/listar' >Formularios</Link> },
                    { title: 'Formulario de caracterización' }]}
            />

            <Form layout="vertical" form={form} onFinish={guardar} scrollToFirstError >

                <Form.Item label='Titulo' name="titulo">
                    <Input />
                </Form.Item>



                <Card title="IDENTIFICACIÓN DEL PISCICULTOR" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item label='Nombre y Apellido' name="nombre_apellido_piscicultor">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label='Documento identificación' name="numero_documento">
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item label='Teléfono' name="telefono_piscicultor">
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label='Dirección de vivienda' name="direccion_de_vivienda">
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <Form.Item label='Asociación o Empresa a la que pertenece' name="empresa_pertenece">
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='¿Está interesado en hacer parte de una asociación?' name="interesado_en_asociacion">
                                <Radio.Group name="radiogroup" defaultValue={'no'}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="INFORMACIÓN SOCIOECONÓMICA" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={4}>
                            <Form.Item label='Edad' name="edad">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item label='Genero' name="Genero">
                                <Radio.Group name="radiogroup" className="block">
                                    <Radio value={'hombre'}>hombre</Radio>
                                    <Radio value={'mujer'}>mujer</Radio>
                                    <Radio value={'otro'}>otro</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item label='Estado civil' name="estado_civil">
                                <Radio.Group name="radiogroup" className="block">
                                    <Radio value={'soltero'}>soltero</Radio>
                                    <Radio value={'casado'}>casado</Radio>
                                    <Radio value={'union_libre'}>union libre</Radio>
                                    <Radio value={'viudo'}>viudo </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item label="Tipo población" name="tipo_poblacion">
                                <Radio.Group name="tipo_poblacion">
                                    <Radio value={'Indigena'}>Indigena</Radio>
                                    <Radio value={'Afrocolombiano'}>Afrocolombiano</Radio>
                                    <Radio value={'Víctima'}>Víctima</Radio>
                                    <Radio value={'Raizal'}>Raizal</Radio>
                                    <Radio value={'Discapacitado'}>Discapacitado</Radio>
                                    <Radio value={'Otro'}>Otro</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {tipo_poblacion == 'Otro' ? <Col xs={24} md={4}>
                            <Form.Item label="¿Cúal?" name="tipo_poblacion_otro">
                                <Input />
                            </Form.Item>
                        </Col> : null}
                        <Col xs={24} md={4}>
                            <Form.Item label='¿Cuenta con servicio de internet?' name="cuenta_servio_internet">
                                <Radio.Group name="radiogroup" defaultValue={1} className="block">
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item label='Escolaridad' name="escolaridad">
                                <Radio.Group name="escolaridad" className="block" >
                                    <Radio value={'Ninguna'}>Ninguna</Radio>
                                    <Radio value={'Primaria'}>Primaria</Radio>
                                    <Radio value={'Secundaria'}>Secundaria</Radio>
                                    <Radio value={'Técnica'}>Técnica</Radio>
                                    <Radio value={'Profesional'}>Profesional</Radio>
                                    <Radio value={'Especialización'}>Especialización</Radio>
                                    <Radio value={'Postgrado'}>Postgrado</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label='¿Cuál es el tiempo de dedicación a la actividad piscícola?' name="tiempo_dedicacion">
                                <Radio.Group name="radiogroup" defaultValue={1} className="block">
                                    <Radio value={'tiempo_completo'}>Tiempo completo</Radio>
                                    <Radio value={'medio_tiempo'}>Medio tiempo</Radio>

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label=' ¿Cuánto tiempo tiene de experiencia en la actividad piscícola?' name="experiencia">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'no_experiencia'} style={{ display: 'block' }}>No tiene experiencia</Radio>
                                    <Radio value={'menos_de_1'} style={{ display: 'block' }}>Menos de 1 año</Radio>
                                    <Radio value={'de_1_a_5'} style={{ display: 'block' }}>1 a 5 años</Radio>
                                    <Radio value={'de_6_a_10'} style={{ display: 'block' }}>6 a 10 años</Radio>
                                    <Radio value={'mas_de_10'} style={{ display: 'block' }}>Más de 10 años</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label='Formación en acuicultura/piscicultura' name="formacion_acuicultura" >
                                <Checkbox.Group style={{ width: '100%' }} className="block">

                                    <Checkbox value="Profesional" >Profesional</Checkbox>

                                    <Checkbox value="Tecnólogo" >Tecnólogo</Checkbox>

                                    <Checkbox value="Técnico" >Técnico</Checkbox>

                                    <Checkbox value="asistencia_cursos" >Asistencia a talleres o cursos cortosD</Checkbox>

                                    <Checkbox value="autonomo" >Aprendizaje autonomo</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>


                <Card title="RESPONSABLE DEL CULTIVO" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item label='Nombre y Apellido' name="nombre_apellido_responsable">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label='telefono' name="telefono">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>


                <Card title="DESCRIPCIÓN DEL PREDIO" style={{ marginBottom: 32 }}>

                    <Row gutter={16}>
                        <Col xs={24} md={4}>
                            <Form.Item label='latitud' name="latitud">
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item label='longitud' name="longitud">
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item label='altitud' name="altitud">
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item label='Área del predio (Hectáreas)' name="area_predio">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <Form.Item label='Municipio' name="Municipio">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item label='Corregimiento o Vereda' name="Corregimiento">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item label='Nombre del predio' name="nombre_predio">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>


                <Card title="VOLUMEN DE AGUA" style={{ marginBottom: 32 }}>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item label=' Volumen total de agua del sistema productivo (m3)' name="volumen_agua">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label='Volumen de agua en uso (m3)' name="agua_en_uso">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>


                <Card title="CARACTERÍSTICAS DEL SISTEMA PRODUCTIVO" style={{ marginBottom: 32 }}>
                    <Alert message="Describa cada estanque del productor" />
                    <br></br>



                    <div style={{ marginBottom: 32 }} >
                        <Form.List name='estanques'>
                            {(estanques, { add, remove }) => (
                                <div>
                                    {estanques.map((field, i) => (
                                        <div key={i}>
                                            <Estanque field={field} index={i} remove={remove} />
                                        </div>
                                    ))}
                                    <Button type="primary" style={{ marginTop: 23 }} onClick={() => add()}>Añadir estanque<div style={{ width: 2, height: 40, background: 'blue', position: 'absolute', top: -40 }}></div></Button>
                                </div>
                            )}
                        </Form.List>
                    </div>
                </Card>

                <Card title="Sistemas de Producción" style={{ marginBottom: 32 }}>
                    <Row gutter={16}>
                        <Col>
                            <Form.Item name="sistema_produccion" label="" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Biofloc">Biofloc</Checkbox>

                                    <Checkbox value="ras">RAS- Recirculación</Checkbox>

                                    <Checkbox value="Acuaponía">Acuaponía</Checkbox>

                                    <Checkbox value="Tradicional">Tradicional</Checkbox>

                                    <Checkbox value="Agro-Acuicultura">Agro-Acuicultura</Checkbox>

                                    <Checkbox value="Otro">Otro (¿Cuál?)</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="sistema_produccion_cual" label="cual" >
                        <Input />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="Tipos_de_producción" label="Tipos de producción" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Alevinaje">Alevinaje</Checkbox>

                                    <Checkbox value="Engorde">Engorde</Checkbox>

                                    <Checkbox value="Peces_ornamentales">Peces ornamentales</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="Sistema-de-cultivo" label="Sistema de cultivo" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Monocultivo'}>Monocultivo</Radio>
                                    <Radio value={'Policultivo'}>Policultivo</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="Causas-de-muerte" label="Causas de muerte" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Oxígeno">Oxígeno</Checkbox>

                                    <Checkbox value="Temperatura">Temperatura</Checkbox>

                                    <Checkbox value="Nitritos">Nitritos</Checkbox>


                                    <Checkbox value="Enfermedades">Enfermedades(Bacterias)</Checkbox>

                                    <Checkbox value="Enfermedades(Hongos)">Enfermedades (Hongos)</Checkbox>

                                    <Checkbox value="Depredadores">Depredadores</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={4}>
                            <Form.Item name="Causas-de-muerte_cual" label="cual" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="COMERCIALIZACIÓN" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="produccion_aproximada_anual" label="Producción anual aproximada (Toneladas/por año)" >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="clasificacion_del_acuicultor_segun_productor" label="Clasificación del acuicultor según producción" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="10">Acuicultor de subsistencia (hasta 10 toneladas/año)</Checkbox>

                                    <Checkbox value="10-1-22">Pequeño acuicultor (10,1-22 toneladas/año)</Checkbox>

                                    <Checkbox value="22-1-240">Mediano acuicultor (22,1-240 toneladas/año)</Checkbox>

                                    <Checkbox value="240">Gran acuicultor (240 toneladas/año en adelante)</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="como_vende_producto" label="¿Cómo vende el producto final?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Eviscerado">Eviscerado</Checkbox>

                                    <Checkbox value="Descamado">Descamado</Checkbox>

                                    <Checkbox value="Arrollado">Arrollado</Checkbox>

                                    <Checkbox value="Entero">Entero</Checkbox>

                                    <Checkbox value="Filete">Filete</Checkbox>

                                    <Checkbox value="Salado">Salado</Checkbox>

                                    <Checkbox value="servicio_mesa">Servido a la mesa</Checkbox>

                                    <Checkbox value="Otro">Entero</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="como_vende_producto_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="cual_tipo_de_comercializacion" label="¿Cuál es el tipo de comercialización?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Municipal">Municipal</Checkbox>

                                    <Checkbox value="Departamental">Departamental</Checkbox>

                                    <Checkbox value="Nacional">Nacional</Checkbox>

                                    <Checkbox value="Internacional">Internacional</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="vehiculos_para_comercializar" label="¿La empresa tiene vínculos formales para la comercialización del producto?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="siempre">Siempre tiene vínculos comerciales</Checkbox>

                                    <Checkbox value="algunas">Algunas veces tiene vínculos comerciales</Checkbox>

                                    <Checkbox value="nunca">Nunca ha tenido vínculos comerciales</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="lleva_registros_finacieros" label="Lleva registros financieros de los gastos" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3>Caracterización de las especies cultivadas</h3>
                    <Form.List name='caracterizacion_de_las_especies_cultivadas'>
                        {(registros, { add, remove }) => (
                            <div>
                                {registros.map((field, i) => (<CaracterizacionEspecieCultivada key={i} field={field} index={i} remove={remove} />))}
                                <Button type="primary" style={{ marginTop: 23 }} onClick={() => add()}>Añadir caracterización<div style={{ width: 2, height: 40, background: 'blue', position: 'absolute', top: -40 }}><div style={{ position: "absolute", width: 10, height: 10, left: -4, background: 'blue', borderRadius: '50%' }} ></div></div></Button>
                            </div>
                        )}

                    </Form.List>
                    <Row style={{ marginTop: 32 }} gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="proceso_sacrificio" label="El proceso de sacrificio lo realiza mediante el uso de la técnica de:" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="shock">Shock térmico "Mezcla de agua + hielo + sal"</Checkbox>

                                    <Checkbox value="aturdimiento">Aturdimiento por golpe</Checkbox>

                                    <Checkbox value="asfixia">Por Asfixia</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="proceso_sacrificio_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="NUTRICIÓN ANIMAL" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="tipo_alimentacion_utilizada_sistema" label="¿Qué tipo de alimentación utiliza en su sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} className="block">
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Comercial">Comercial</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Natural">Natural</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Complementaria">Complementaria</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="marca_de_concentrado_usa_para_alimentacion" label=" ¿Qué marca de concentrado usa para la alimentación de los peces del cultivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Solla">Solla</Checkbox>

                                    <Checkbox value="Italcol">Italcol</Checkbox>

                                    <Checkbox value="Finca">Finca</Checkbox>

                                    <Checkbox value="Contegral">Contegral</Checkbox>

                                    <Checkbox value="Ninguno">Ninguno</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="marca_de_concentrado_usa_para_alimentacion_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="razon_compra_concentrado" label="¿Cuál es la razón por la que escoge la marca de concentrado?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Precio">Precio</Checkbox>

                                    <Checkbox value="Calidad">Calidad</Checkbox>

                                    <Checkbox value="Disponibilidad">Disponibilidad</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="razon_compra_concentrado_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="tiene_alianza_comercial " label=" ¿Tiene alianza comercial para la compra del alimento?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="raccion_diaria_alimneto" label="¿Cómo calcula la ración diaria alimenticia que suministra a los peces?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="recomendada">Por tabla recomendada por la marca del concentrado</Checkbox>

                                    <Checkbox value="sugerida">Por tabla sugerida por el profesional</Checkbox>

                                    <Checkbox value="ajustada">Por tabla ajustada por el productor</Checkbox>

                                    <Checkbox value="a_saciedad">A saciedad</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="tiene_cuenta_frecuencia " label="¿Tiene en cuenta la frecuencia de alimentación según la etapa del ciclo productivo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="lleva_registro_consumo" label="¿Lleva registros del consumo de alimentación por ciclo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="tiene_bodega" label="¿Tiene bodega de almacenamiento de alimentos en buenas condiciones?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="evidencia_arpovechamiento" label="¿Evidencia aprovechamiento total del alimento suministrado a los peces?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="suministra_probioticos" label="¿Suministra probióticos o suplementos durante la alimentación?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="realcion_proteico_crecimineto" label="¿Conoce la relación entre las fases de crecimiento y el contenido proteico del alimento que debe suministrar al pez?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="cantidad_proteina_suministrada" label="¿Que porcentajes de proteína suministra a sus peces?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="40-45">40-45% (Iniciación-aguas calidas)</Checkbox>

                                    <Checkbox value="32-38">32-38% (Levante-aguas calidas)</Checkbox>

                                    <Checkbox value="20-30">20-30% (Engorde-aguas calidas)</Checkbox>

                                    <Checkbox value="48-50">48-50% (Iniciación-Trucha)</Checkbox>

                                    <Checkbox value="40-45%"> 40-45% (Levante-Trucha)</Checkbox>

                                    <Checkbox value="40">40% (Engorde-Trucha)</Checkbox>

                                    <Checkbox value="Ninguno">Ninguno</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="realiza_biometrias" label="Realiza biometrías" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="lleva_registros_tecnicos" label="Lleva registros de indicadores técnicos de producción" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}> si ¿cual?'</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="lleva_registros_tecnicos_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <div style={{ marginBottom: 32 }} >
                            <Form.List name='tasa'>
                                {(tasa, { add, remove }) => (
                                    <div>
                                        {tasa.map((field, i) => (
                                            <div key={i}>
                                                <TasaConversionAlimenticia field={field} index={i} remove={remove} />
                                            </div>
                                        ))}
                                        <Button type="primary" style={{ marginTop: 23 }} onClick={() => add()}>Añadir Tasa conversion alimenticia<div style={{ width: 2, height: 40, background: 'blue', position: 'absolute', top: -40 }}></div></Button>
                                    </div>
                                )}
                            </Form.List>
                        </div>
                    </Row>


                </Card>

                <Card title="CALIDAD DE AGUA E IMPACTO AMBIENTAL" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="de_que_fuente_proviene" label="¿De qué fuente proviene el agua que utiliza en el sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="pozo-subterraneo">Pozo subterráneo</Checkbox>

                                    <Checkbox value="cuerpo">Cuerpo de agua (río, lago, ciénaga, etc)</Checkbox>

                                    <Checkbox value="agua-lluvia">Agua lluvia</Checkbox>

                                    <Checkbox value="otro">Otro (¿Cuál?)</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="de_que_fuente_proviene_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="con_que_frecuencia_medicion" label="¿Con qué frecuencia realiza las mediciones de los parámetros de calidad de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Diaria'}>Diaria</Radio>
                                    <Radio value={'Semanal'}>Semanal</Radio>
                                    <Radio value={'Quincenal'}>Quincenal</Radio>
                                    <Radio value={'no'}>No realiza</Radio>
                                    <Radio value={'Otro'}>Otro (¿Cuál?)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="con_que_frecuencia_medicion_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="parametros_mide_cuales" label="¿Qué parámetros de calidad de agua mide?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Nitratos">Nitratos</Checkbox>

                                    <Checkbox value="Nitritos">Nitritos</Checkbox>

                                    <Checkbox value="Amonio">Amonio</Checkbox>

                                    <Checkbox value="pH">pH</Checkbox>

                                    <Checkbox value="oxigeno">Oxígeno disuelto</Checkbox>

                                    <Checkbox value="Turbidez">Turbidez</Checkbox>

                                    <Checkbox value="Alcalinidad">Alcalinidad</Checkbox>

                                    <Checkbox value="Dureza">Dureza</Checkbox>

                                    <Checkbox value="Cloruro">Cloruro</Checkbox>

                                    <Checkbox value="Fosfato">Fosfato</Checkbox>

                                    <Checkbox value="Sólidos_sedimentables">Sólidos sedimentables</Checkbox>

                                    <Checkbox value="solidos_suspendidos">Sólidos suspendidos totales</Checkbox>

                                    <Checkbox value="Carbono">Carbono</Checkbox>

                                    <Checkbox value="Nitrogeno">Nitrogeno</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="parametros_mide_cuales_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="con_que_frecuencia_realiza_cambio_agua" label="¿Con qué frecuencia realiza recambio de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Constantemente'}>Constantemente</Radio>
                                    <Radio value={'Diariamente'}>Diariamente</Radio>
                                    <Radio value={'Semanalmente'}>Semanalmente</Radio>
                                    <Radio value={'Mensualmente'}>Mensualmente</Radio>
                                    <Radio value={'no'}>No realiza</Radio>
                                    <Radio value={'Otro'}>Otro (¿Cuál?)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="con_que_frecuencia_realiza_cambio_agua_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="porcentaje_cambio_agua" label="¿Cuál es el porcentaje (%) de recambio del agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'5'}>5</Radio>
                                    <Radio value={'10'}>10</Radio>
                                    <Radio value={'20'}>20</Radio>
                                    <Radio value={'30'}>30</Radio>
                                    <Radio value={'40'}>40</Radio>
                                    <Radio value={'50'}>50</Radio>
                                    <Radio value={'60'}>60</Radio>
                                    <Radio value={'70'}>70</Radio>
                                    <Radio value={'80'}>80</Radio>
                                    <Radio value={'90'}>90</Radio>
                                    <Radio value={'100'}>100</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="que_haces_con_agua_saliente" label="¿Qué hace con el agua que sale del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="riego_huertas">Riego de cultivos agrícolas o huertas</Checkbox>

                                    <Checkbox value="vertimiento_natural">Vertimiento en cuerpo de agua natural</Checkbox>

                                    <Checkbox value="alcantarillado">Vertimiento al alcantarillado</Checkbox>

                                    <Checkbox value="Acuaponía">Acuaponía</Checkbox>

                                    <Checkbox value="RAS">RAS</Checkbox>

                                    <Checkbox value="Otra">Otra</Checkbox>

                                    <Checkbox value="No_reutiliza_agua">No reutiliza el agua</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="que_haces_con_agua_saliente_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="mide_fisicoquimicos_microbiologicos_del_agua" label="¿Mide los parámetros fisicoquímicos y microbiológicos del agua que sale del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'ocasionalmente'}>Ocasionalmente</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="retira_alimnetos_sobrenadante" label="¿Retira el alimento sobrenadante y heces de los estanques?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'ocasionalmente'}>Ocasionalmente</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item name="realiza_sifoneo_" label=" ¿Realiza sifoneo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_requiere'}>El sistema productivo no lo requiere</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="producto_para_calidad_de_agua" label="¿Utiliza algún producto para mantener o mejorar la calidad del agua del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Cal">Cal</Checkbox>

                                    <Checkbox value="Melaza">Melaza</Checkbox>

                                    <Checkbox value="Microorganismos">Microorganismos biorremediadores (Probióticos)</Checkbox>

                                    <Checkbox value="Sal">Sal</Checkbox>

                                    <Checkbox value="Vinagre">Vinagre</Checkbox>

                                    <Checkbox value="Peroxido-hidrogeno">Peroxido de hidrogeno</Checkbox>

                                    <Checkbox value="Bicarbonato">Bicarbonato</Checkbox>

                                    <Checkbox value="Otro">Otro</Checkbox>

                                    <Checkbox value="No_realiza">No realiza</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="producto_para_calidad_de_agua_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="alguna_especie_auorizada_unap" label="¿Tiene alguna especie no autorizada por la AUNAP?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="Carpa">Carpa</Checkbox>

                                    <Checkbox value="Basa-pangasus">Basa o pangasus</Checkbox>

                                    <Checkbox value="No tiene">No tiene</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="alguna_especie_auorizada_unap_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="medidas_contra_fugas" label="¿Cuenta con medidas para evitar fugas de especies cultivadas?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="que_hace_con_residuos_pesqueros" label="¿Qué hace con los residuos pesqueros?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >

                                    <Checkbox value="alimento_animales">Se usan como alimento para animales</Checkbox>

                                    <Checkbox value="se_quema">Se quema</Checkbox>

                                    <Checkbox value="cielo_abierto">Se disponen a cielo abierto</Checkbox>

                                    <Checkbox value="Se_vende">Se vende</Checkbox>

                                    <Checkbox value="Se_entierrao">Se entierra</Checkbox>

                                    <Checkbox value="Otro">otro ¿cual?</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="que_hace_con_residuos_pesqueros_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="cuenta_permiso_unap" label=" ¿Cuenta actualmente con el permiso de cultivo expedido por la AUNAP?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="cuenta_permiso_concepcion_de_aguas" label="¿Cuenta actualmente con el permiso para concesión de aguas?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                    <Radio value={'no_requiere'}>no requiere</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="cuenta_certificado_establecimiento" label="¿Cuenta con el certificado como establecimiento de Acuicultura Biosegura?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="manejo_gestion_residuos" label="¿Realiza manejo y gestión de los residuos y de vertimientos generados en el sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="mantenimineto_infraestructura" label="¿Cuenta con un Programa de Mantenimiento de Infraestructura y Equipos?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'_no_equipos'}>No cuenta con programas, pero realiza el mantenimiento pertinente a la infraestructura y a los equipos</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <Form.Item name="manejo_plagas" label="¿Cuenta con el programa de manejo de plagas y predadores?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_cuenta'}>No cuenta con programas, pero realiza el manejo de plagas y predadores de manera correcta</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="programa_de_limpieza" label=" ¿Cuenta con un Programa de limpieza y desinfección del sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_cuenta'}>No cuenta con programas, pero realiza limpieza y desinfección en todo el sistema productivo de manera periódica.</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="reservorio_agua" label="¿Cuenta con reservorio de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={6}>
                            <Form.Item name="PROYECCIÓN_DEL_PROYECTO" label="PROYECCIÓN DEL PROYECTO" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >


                                    <Checkbox value="cantidad_estanque">Aumentar la cantidad de estanques</Checkbox>

                                    <Checkbox value="sistemas">Implementar sistemas de aireación para aumentar la producción</Checkbox>

                                    <Checkbox value="sistemas_productivos">Implementar nuevos sistemas de producción (Biofloc, acuaponía, RAST, otros)</Checkbox>

                                    <Checkbox value="Implementar_alimentación">Implementar alimentación alternativa</Checkbox>

                                    <Checkbox value="Otro">otro ¿cual?</Checkbox>

                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Form.Item name="PROYECCIÓN_DEL_PROYECTO_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>

                        </Col>
                    </Row>
                </Card>

                <Card style={{ marginBottom: 32 }}>
                    {listarArchivos()}
                    <Dragger {...props} showUploadList={false} multiple={false} fileList={[]}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">AGREGA LOS ARCHIVOS A ENVIAR DENTRO DE ESTA AREA</p>
                        <p className="ant-upload-hint">
                            TODOS LOS ARCHIVOS NECESARIOS PARA COMPLETAR LA INFORMACION DE ESTE FORMULARIO AGREGARLOS ACA
                        </p>
                    </Dragger>
                </Card>

                <Card style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <Form.Item name={'firma_productor'} label="Firma del productor">
                                <CampoFirma />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="firma_asesor" label="Firma Asesor">
                                <CampoFirma />
                            </Form.Item>

                        </Col>
                        <Col xs={24} md={24}>
                            <Tooltip title={'Enviar formulario'}>
                                <Button loading={cargando} type="primary" style={{ backgroundColor: "#81BD00" }} htmlType="submit" >Enviar</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Card>

            </Form>
        </Spin>
    </div >
}