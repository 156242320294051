import { useEffect, useState } from "react"

const useConexion=()=>{
    const [online,setOnline] = useState(true)

    useEffect(()=>{
        window.addEventListener("online", function() {
            setOnline(true)
          })
          
          window.addEventListener("offline", function() {
            setOnline(false)
          })
    },[])

    return online
}

export default useConexion