import { Breadcrumb, DatePicker, Button, Popconfirm, Space, Table, Tooltip, Card, Row, Col, Form, Select } from "antd"
import { Link, useParams } from "react-router-dom"
import ReactEcharts from "echarts-for-react";
import { useForm } from "antd/es/form/Form"
import API from "../../API";
import { useState } from "react";
import useAuth from "../../Contextos/Auth/AuthContext";
import { PRODUCTOR } from "../../Constantes";
import { useEffect } from "react";
import Dayjs from "dayjs"
import { Parametro } from "./Parametro";
import Typography from "antd/es/typography/Typography";
import useBaseDatos from "../../Ganchos/BaseDatos";
import { TIPO_ESTANQUE, TIPO_GRANJA } from "../../Base/Contantes";


export const Graficas = () => {
    const { RangePicker } = DatePicker;
    const { base_de_datos } = useBaseDatos()
    const { user } = useAuth()
    const [form] = useForm()
    const [insumos, setInsumos] = useState([])
    const [totalGranjas, setTotalGranjas] = useState(0)
    const [granja, setGranjas] = useState([])
    const [productor, setProductor] = useState([])
    const [estanque, setEstanque] = useState([])
    const [parametros, setParametros] = useState([])
    const onChange = (value) => {
        console.log(value)
    };
    const cargar_granjas_delusuario = () => {
        cargar_granjas(user._id)
    };
    const onChangeEstanque = (value) => {
        cargar_estanque(value)
    };

    const cargar_granjas = async () => {
        
        const data = await base_de_datos?.find({ selector: { type: TIPO_GRANJA }})  
      
        setGranjas(data?.docs)
        
    }


    const cargar_estanque = async (id) => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE, id_granja: id } })
       
        setEstanque(data?.docs)
        // try {
        //     const { data } = await API(`api/usuario/estanque-granja/${id}`)
        //     console.log(data.doc, "estanque")
        //     setEstanque(data.doc)

        // } catch (error) {
        //     console.log(error)
        // }
    }
    //
    const listarInsumos = async (id) => {
        // try {
        //     const { data } = await API(`api/usuario/insumos/${id}`)
        //     setInsumos(data?.doc)
        // } catch (error) {
        //     console.log(error, "error -----")
        // }

    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    async function cargar(payload) {
        try {
           
            const { data } = await API.get(`api/graficas/${payload.estanque}`, { params: { inicio: payload.fecha[0].toDate(), fin: payload.fecha[1].toDate() } })
           
            setParametros(data.docs)
            const totales = await API(`api/graficas/consumo/granja/`, { params: { inicio: payload.fecha[0].toDate(), fin: payload.fecha[1].toDate() } })

            setTotalGranjas(totales.data.docs)
        } catch (error) {
            console.log(error)
        }
    }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const representacion = () => {
        for (let index = 0; index < 4; index++) {
            return <>
                <Card>
                    <Parametro parametros={parametros} nombre={'ph'} />
                    {/* <ReactEcharts option={option} /> */}
                </Card>
            </>

        }
    }
    const graja_consumo = {
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: ["fecha"]
        },
        xAxis: {
            boundaryGap: false,
            type: 'category',
            data: parametros?.map(e => e?.label)
        },
        yAxis: { type: 'value' },
        series: [
            {
                name: "Gastos Granjas",
                label: 'prueba',
                type: 'line',
                stack: 'Total',
                emphasis: {
                    focus: 'series'
                },
                data: parametros.map(e => e?.value),
                color: "red"
            },
        ]
    };

    useEffect(() => {
        form.setFieldValue('fecha', [Dayjs().subtract(1, 'month').startOf("month"), Dayjs()])
        // total_consumo_granjas()
        // form.resetFields()
        cargar_granjas_delusuario()
        // traerDatos()
    }, [])
    return <div>
        <Space size={72}>
            <Breadcrumb items={[{ title: <Link to='/' >Inicio</Link> }, { title: 'graficas' }]} />
        </Space>
        <Row gutter={10} >
            <Col span={24}>
                <Card>
                    <Form layout="vertical" form={form} onFinish={cargar}>

                        <Row gutter={16} align={'middle'}> 

                            <Col xs={24} md={12}>
                                <Form.Item name="granja" label="Granja" rules={[{ required: true, message: 'Ingrese la Granja', },]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Granja"
                                        optionFilterProp="children"
                                        onChange={onChangeEstanque}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={granja.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="estanque" label="estanque" rules={[{ required: true, message: 'Ingrese el estanque', },]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Estanque"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={estanque.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item name="fecha" label="fecha" rules={[{ required: true, message: 'ingrese por favor el Año', },]}>
                                    <RangePicker picker="week" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Button type="primary" style={{ backgroundColor: "#81BD00" }} htmlType="submit" >Filtrar</Button>
                            </Col>
                            <br></br>
                            <br></br>
                            <br></br>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <Col span={24}>
                <Card>
                    <Parametro parametros={parametros.map(e => ({ value: e?.ph, label: e?.fecha }))} nombre={'ph'} color={'#58D68D'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.amonio, label: e?.fecha }))} nombre={'Amonio'} color={'#3498DB'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.oxigeno, label: e?.fecha }))} nombre={'Oxigeno'} color={'#F4D03F'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.temperatura, label: e?.fecha }))} nombre={'Temperatura'} color={'#E74C3C'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.tds, label: e?.fecha }))} nombre={'TDS'} color={'#AF7AC5'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.os, label: e?.fecha }))} nombre={'OS'} color={'#5499C7'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.o20, label: e?.fecha }))} nombre={'O20'} color={''} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.rs232, label: e?.fecha }))} nombre={'RS232'} color={'#EB984E'} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.nitrito, label: e?.fecha }))} nombre={'Nitrito'} color={''} />
                    <Parametro parametros={parametros.map(e => ({ value: e?.nitrato, label: e?.fecha }))} nombre={'Nitrato'} color={'#EB944E'} />


                    {/* <Parametro parametros={parametros} nombre={'ph'} />
                    <Parametro parametros={parametros} nombre={'ph'} /> */}
                </Card>

            </Col>           
            
        </Row>
    </div>

}