import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Popover, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { TIPO_INSUMOS } from "../../../Base/Contantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import { InsumoGuardar } from "../Guardar"
import { IMG_INVENTARIO } from "../../../Constantes"

export const Insumos = () => {
    const [insumos, setInsumos] = useState([])
    const { base_de_datos } = useBaseDatos()
    const [insumo, setInsumo] = useState(null)
    const user = useAuth()

    const cargar = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_INSUMOS, productor: user.user.productor } })

        setInsumos(data?.docs)
    }

    const borrar = async (doc) => {
        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    useEffect(() => {
        cargar()
    }, [base_de_datos])

    return <div>
        <Space size={72}>

            <Breadcrumb style={{ alignItems: 'center' }} items={[
                { title: <Link to='/' >inicio</Link> },
                { title: 'Insumos' }
            ]} />
            <Button type="link" icon={<PlusOutlined />} onClick={() => setInsumo({})}>Registrar nuevo insumo</Button>
        </Space>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra los insumos que usarás en tu gestión" title="Módulo insumos" trigger='click'>
                <img width={128} src={IMG_INVENTARIO} />
            </Popover>
        </div>

        <InsumoGuardar insumo={insumo} setInsumo={setInsumo} alGuardar={cargar} />
        <Table dataSource={insumos} rowKey='_id' pagination={false}>
            <Table.Column title='nombre' dataIndex={'nombre_insumo'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title='Editar'>
                    <Button icon={<EditOutlined />} shape='circle' onClick={() => setInsumo(doc)}></Button>
                </Tooltip>
                <Tooltip title='Eliminar'>
                    <Popconfirm title="Se borrará el registro" onConfirm={() => borrar(doc)}>
                        <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                    </Popconfirm>
                </Tooltip>
            </Space>} />
        </Table>
    </div>
}