export const TIPO_GRANJA='Granja'
export const TIPO_ESTANQUE='Estanque'
export const TIPO_PRODUCCION='produccion'
export const MESES = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ];
export const TIPO_INSUMOS='insumo'
export const TIPO_CICLOS='ciclo'
export const TIPO_PARAMETROS='parametros'
export const TIPO_FORMULARIOS='formularios'