import { DeleteOutlined, EditOutlined, CaretDownOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, DatePicker, Form, Popconfirm, Row, Select, Space, Table, Typography, Divider, Tooltip, Popover } from "antd"
import Dropdown from "antd/es/dropdown/dropdown"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Papa from 'papaparse'
import { TIPO_ESTANQUE, TIPO_GRANJA, TIPO_PARAMETROS } from "../../Base/Contantes"
import { ASISTENTE, PRODUCTOR } from "../../Constantes"
import useAuth from "../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../Ganchos/BaseDatos"
import { ParametrosGuardar } from "../Guardar"
import { Precargar } from "../Guardar/Precarga"
import img_parametro from "../../static/parametros.png"
import GranjaCelda from "../../Componentes/GranjaCelda"
import EstanqueCelda from "../../Componentes/EstanqueCelda"

export const Parametros = () => {
    const [parametros, setParametros] = useState([])
    const { base_de_datos } = useBaseDatos()
    const [element, setelement] = useState(null)
    const [parametro, setParametro] = useState(null)
    const [parametroPreca, setParametroPreca] = useState(null)
    const [form] = Form.useForm()
    const [filtrado, setFiltrado] = useState(false)
    const [selectParametro, setSeleParametro] = useState([])
    const [granjas, setGranjas] = useState([])
    const [estanque, setEstanque] = useState([])
    const [estanqueglobales, setEstanqueGlobales] = useState([])
    const user = useAuth()

    const granja = Form.useWatch('granja', form)

    const cargar = async (payload) => {

        const filtros = { selector: { type: TIPO_PARAMETROS, ...payload } }

        if (user.user.rol === ASISTENTE.value) {
            filtros.selector.usuario = user.user._id
        }


        const data = await base_de_datos?.find(filtros)

        setParametros(data?.docs)
    }

    const cargar_granjas = async () => {

        const data = await base_de_datos?.find({ selector: { type: TIPO_GRANJA } })

        setGranjas(data?.docs)

    }

    const cargarEstanque = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE, id_granja: granja } })

        setEstanque(data?.docs)
    }

    const Estanque_globales = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE } })

        setEstanqueGlobales(data?.docs)
    }

    const borrar = async (doc) => {

        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    const filtrado_parametros = (payload) => {
        const { fecha, ...resto } = payload

        if (!resto.granja) {
            delete resto.granja
        }

        if (!resto.estanque) {
            delete resto.estanque
        }
        const filtros = {
            fecha: { $gte: fecha[0].toDate(), $lte: fecha[1].toDate() },
            ...resto
        }
        cargar(filtros)

    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const DescargarCSV =  () => {
        console.log(estanqueglobales,"granja------")
        const dataCSV =  parametros?.map(e => ( {           
                granja:granjas.find(g=>g._id==e.granja).nombre,
                estanque:estanqueglobales.find(g=>g._id==e.estanque).nombre,
                amonio: e?.amonio,
                anio_creacion: e?.anio_creacion,
                fecha: e?.fecha,
                o20: e?.o20,
                os: e?.os,
                oxigeno: e?.oxigeno,
                ph: e?.ph,
                rs232: e?.rs232,
                tds:e?.tds,
                temperatura:e?.temperatura                
            }
        ))
        console.log(dataCSV, "csv")
        const csv = Papa.unparse(dataCSV)
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Parámetros.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }




    const items = [
        {
            key: '1',
            label: (
                <Typography.Text onClick={() => setParametro({})}>Manual</Typography.Text>
            )
        },
        {
            key: '2',
            label: (
                <Typography.Text onClick={() => setelement({})}>Carga masiva</Typography.Text>
            )
        }
    ]

    useEffect(() => {
        form.setFieldValue('estanque', '')
        if (granja) {
            cargarEstanque()
        } else {

            setEstanque([])
        }
    }, [granja])

    useEffect(() => {
        cargar_granjas()
    }, [base_de_datos])

    useEffect(() => {
        form.setFieldValue('fecha', [dayjs().subtract(6, 'month'), dayjs()])
        form.submit()
        Estanque_globales()
    }, [])

    return <div>
        <Space size={72}>
            <Breadcrumb items={[{ title: <Link to='/' >inicio</Link> }, { title: 'parámetros' }]} />
            <Dropdown.Button
                menu={{
                    items
                }}
                icon={<CaretDownOutlined />}
            >
                Registrar Parámetros y mediciones
            </Dropdown.Button>

            <Button type="primary" htmlType="submit" onClick={() => DescargarCSV()} style={{ backgroundColor: "#81BD00" }}>Descargar CSV</Button>
        </Space>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra registra de manera manual o masiva tus parámetros" title="Módulo parámetros" trigger='click'>
                <img width={128} src={img_parametro} />
            </Popover>
        </div>
        <Precargar element={element} setParametro={setelement} alGuardar={cargar} />
        <ParametrosGuardar parametro={parametro} setParametro={setParametro} alGuardar={cargar} />
        <Divider />
        <Form layout="vertical" form={form} onFinish={filtrado_parametros}>
            <Row gutter={10}>
                <Col sm={24} md={8}>
                    <Form.Item name="fecha" label="Rango de fecha" rules={[{ required: true, message: 'ingrese el  Usuario', },]}>
                        <DatePicker.RangePicker allowClear={false} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item name="granja" label="Granja">
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            allowClear
                            placeholder="Seleccione Granja"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={granjas.map(e => ({ value: e?._id, label: e?.nombre }))}
                            disabled={filtrado}
                        />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item name="estanque" label="Estanque" >
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            allowClear
                            placeholder="Seleccione Parametro"
                            optionFilterProp="children"
                            // onChange={onChange}
                            filterOption={filterOption}
                            options={estanque?.map(e => ({ value: e?._id, label: e?.nombre }))}
                            disabled={filtrado}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: "#81BD00" }}>Consultar</Button>
        </Form>
        <Divider />
        <Table dataSource={parametros} rowKey='_id' pagination={false} scroll={{ x: 400, y: 700 }} >
            <Table.Column title='fecha' width={100} fixed dataIndex={'fecha'} render={(f) => dayjs(f).format("YYYY-MM-DD hh:mm a")} />
            <Table.Column title="Granja" width={100} fixed dataIndex={'granja'} render={(id)=><GranjaCelda id={id} granjas={granjas} />} />
            <Table.Column title="Estanque" width={100} fixed dataIndex={'estanque'} render={(id)=><EstanqueCelda id={id} estanques={estanqueglobales} />} />
            <Table.Column title='Tmp. °C' dataIndex={'temperatura'} />
            <Table.Column title='PH' dataIndex={'ph'} />
            <Table.Column title='Oxigeno' dataIndex={'oxigeno'} />
            <Table.Column title='amonio' dataIndex={'amonio'} />
            <Table.Column title='TDS' dataIndex={'tds'} />
            <Table.Column title='OS' dataIndex={'os'} />
            <Table.Column title='o20' dataIndex={'o20'} />
            <Table.Column title='RS232' dataIndex={'rs232'} />
            <Table.Column title='Nitrito' dataIndex={'nitrito'} />
            <Table.Column title='Nitrato' dataIndex={'nitrato'} />

            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title='Editar'>
                    <Button icon={<EditOutlined />} shape='circle' onClick={() => setParametro(doc)}></Button>
                </Tooltip>
                <Tooltip title='Eliminar'>
                    <Popconfirm title="Se borrar la granja y sus estantes" onConfirm={() => borrar(doc)}>
                        <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                    </Popconfirm>
                </Tooltip>
            </Space>} />
        </Table>


    </div>
}