import { createContext, useEffect, useState } from "react"
import PouchDB from 'pouchdb-browser'
import pouchFind from 'pouchdb-find';
import { Spin, Typography, notification } from "antd";
import useAuth from "../Auth/AuthContext";
import { useLocation } from "react-router-dom";
import { ASISTENTE, PRODUCTOR, TKOL } from "../../Constantes";
import crypto from 'crypto-js';
import useConexion from "../../Hook/Conexion";
import { TIPO_PARAMETROS } from "../../Base/Contantes";
PouchDB.plugin(pouchFind);

export const ContextoBaseDatos = createContext()


let sincronizar;

const BaseDatos = ({children}) => {
    const { pathname } = useLocation();
    const { user } = useAuth()
    const [base_de_datos,setBaseDatos] = useState(null)
    const [nube,setNube] = useState(false)
    const online = useConexion()

    const iniciarRemota=async (local)=>{
       

        if(!localStorage.getItem('abc')){
            return
        }

       

        try{
           
            const bdname=crypto.AES.decrypt(localStorage.getItem('abc'),TKOL).toString(crypto.enc.Utf8)
            const bduser=crypto.AES.decrypt(localStorage.getItem('jul'),TKOL).toString(crypto.enc.Utf8)
            const bdpassword=crypto.AES.decrypt(localStorage.getItem('xyz'),TKOL).toString(crypto.enc.Utf8)
            const remote = new PouchDB(`https://${bduser}:${bdpassword}@admin.agrifishcol.com/${bdname}`)
            const info = await remote.info()
        
            sincronizar = local.sync(remote,{
                live:true,
                retry:true
            }).on('paused',info=>{
            }).on('active',info=>{
            }).on('change',cambios=>{
                console.log(cambios)
                if(cambios.direction=='pull'){
                    cambios.change.docs.forEach((d)=>{
                        if(d.type==TIPO_PARAMETROS){
                            console.log("Evento ",`parametro-${d.estanque}`)
                            window.document.dispatchEvent(new CustomEvent(`parametro-${d.estanque}`, {
                                detail: ''
                            }));
                        }
                    }) 
                }
            }).on("error",(err)=>{
                console.log({error:err})
                if(err?.error=='unauthorized'){
                    setNube(false)
                    notification.error({
                        message:'Sincronicación desactivada',
                        description:'Los datos que registren no serán respaldados en la nube de Agrifish, no cierre sesión o perderá todos los datos. Por favor contactar a su asesor.'
                    })
                }
            })
            setNube(true)
        }catch(error){
            console.log(error)

            if(error.name=='unauthorized'){
                setNube(false)
                notification.error({
                    message:'Sincronicación desactivada',
                    description:'Los datos que registren no serán respaldados en la nube de Agrifish, no cierre sesión o perderá todos los datos. Por favor contactar a su asesor.'
                })
            }
            
        }
    }
    

    const iniciar = async ()=>{
       
        const local = new PouchDB('acuaponico2',{auto_compaction: true})
        local.compact()
        setBaseDatos(local)
    }

    useEffect(()=>{
       if(user && user?._id){
            iniciar()           
       }

    },[user])

    useEffect(()=>{
        if(base_de_datos && online && [ASISTENTE.value,PRODUCTOR.value].includes(user?.rol)){
            iniciarRemota(base_de_datos)
        }
    },[online,base_de_datos,user])




    if(pathname != '/login' && !base_de_datos){
        return <Spin spinning={true}>
            <Typography.Title>Cargando Datos Locales...</Typography.Title>
        </Spin>
    }

    const value = {
        base_de_datos:base_de_datos,
        nube
    }



    return <ContextoBaseDatos.Provider value={value}>
        {children}
    </ContextoBaseDatos.Provider>
}

export default BaseDatos