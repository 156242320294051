import { DeleteOutlined, EditOutlined, PlusOutlined, CloudUploadOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Popover, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"

import { ASESOR, IMG_INVENTARIO } from "../../../Constantes"
import { Formulario } from "../Guardar"
import { TIPO_FORMULARIOS } from "../../../Base/Contantes"
import { FormularioSubir } from "../Enviar"
import ValidarRol from "../../../Componentes/ValidarRol"

export const ListarFormularios = () => {
    const [formularios, setFormularios] = useState([])
    const { base_de_datos } = useBaseDatos()
    const [formulario, setFormulario] = useState(null)
    const [enviar, setEnviar] = useState(null)
    const navigate = useNavigate()
    const user = useAuth()

    const cargar = async () => {
        const data = await base_de_datos?.find({ selector: { type: 'formularios' } })

        setFormularios(data?.docs)
    }

    const borrar = async (doc) => {
        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    const nuevo = async () => {
        const fecha = new Date();
        const anio_creacion = fecha.getFullYear();
        const nuevo_formulario = await base_de_datos.post({ type: TIPO_FORMULARIOS, usuario: user.user._id, anio_creacion })

        navigate('/formulario/' + nuevo_formulario.id)
    }

    const onCompletado = () => {
        cargar()
    }

    useEffect(() => {
        cargar()
    }, [base_de_datos])

    return <ValidarRol roles={[ASESOR.value]}>
        <Space size={72}>

            <Breadcrumb style={{ alignItems: 'center' }} items={[
                { title: <Link to='/' >inicio</Link> },
                { title: 'Formularios' }

            ]} />
            <Button type="link" icon={<PlusOutlined />} onClick={nuevo}>Registrar nuevo formulario</Button>
        </Space>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra un nuevo formulario" title="Módulo formularios" trigger='click'>
                <img width={128} src={IMG_INVENTARIO} />
            </Popover>
        </div>

        <FormularioSubir enviar={enviar} setEnviar={setEnviar} onCompletado={onCompletado} />
        <Table dataSource={formularios} rowKey='_id' pagination={false}>
            <Table.Column title='Fecha subido' dataIndex={'fecha_subido'} render={(f) => f != '' ? f : 'No ha sido subido'} />
            <Table.Column title='titulo' dataIndex={'titulo'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title='Editar'>
                    <Button icon={<EditOutlined />} shape='circle' onClick={() => navigate(`/formulario/${doc._id}`)}></Button>
                </Tooltip>
                <Tooltip title='Eliminar'>
                    <Popconfirm title="Se borrará el registro" onConfirm={() => borrar(doc)}>
                        <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                    </Popconfirm>
                </Tooltip>
                <Tooltip title='Subir formulario'>
                    <Button icon={<CloudUploadOutlined />} onClick={() => setEnviar(doc._id)} shape='circle'></Button>
                </Tooltip>

            </Space>} />
        </Table>
    </ValidarRol>
}