import { Breadcrumb, Descriptions, Form, Table, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TIPO_FORMULARIOS, TIPO_GRANJA } from "../../../Base/Contantes";
import useBaseDatos from "../../../Ganchos/BaseDatos";
import SignatureCanvas from 'react-signature-canvas'
import carpetaEliminar from "../../../static/eliminar-carpeta.png"
import dayjs from "dayjs";
const GranjaFormulario = () => {
  const { id } = useParams();
  const [cargando, setCargando] = useState(false);
  const [formulario, setFormulario] = useState(null);
  const { base_de_datos } = useBaseDatos();
  const [granja, setGranja] = useState({});
  //listar archivos ------------------------------------
  const listarArchivos = () => {

    if (!formulario) {
      return
    }
    if (!formulario?._attachments) {
      return
    }

    return Object.keys(formulario?._attachments).map((k, i) => {
      return <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}><p onClick={() => descargarArchivo(k)}>{k}</p></div>
    })
  }

  //descargar archivos ---------------------------
  const descargarArchivo = async (nombre_archivo) => {
    const blob =  await base_de_datos.getAttachment(formulario._id,nombre_archivo)

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = nombre_archivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  //-----------------------------------
  const cargar = async () => {
    try {
      setCargando(true);
      const formularios = await base_de_datos.find({
        selector: { type: TIPO_FORMULARIOS, granja: id },
      });
      if (formularios?.docs.length) {
        //setFormulario(formularios.docs[formularios.docs.length-1])
        setFormulario(formularios.docs[formularios.docs.length-1]);
      }
    } catch (error) {
    } finally {
      setCargando(false);
    }
  };
  //firma-------------------------------------
  const CampoFirma = ({ value }) => {

    const ref = useRef()

    useEffect(() => {
      if (ref?.current && value && Array.isArray(value)) {
        ref.current.fromData(value)
        ref.current.off()
      }
    }, [value, ref])

    return <SignatureCanvas
      canvasProps={{
        width: 400,
        height: 200,
        style: { "border": "1px solid #000000" }
      }}
      minWidth={2}
      maxWidth={2}
      ref={ref}
    />
  }

  const cargarGranja = async () => {
    try {
      const doc = await base_de_datos.get(id);
      setGranja(doc);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    cargar();
    cargarGranja();
  }, []);

  

  const miga = [
    {
      title: <Link to="/">Inicio</Link>,
    },
    {
      title: <Link to="/granjas">{granja?.nombre || "Granjas"}</Link>,
    },
    {
      title: "Formulario de caraterización",
    },
  ];

  const { estanques = [], caracterizacion_de_las_especies_cultivadas = [], tasa = [] } = formulario || {};
  if (!cargando && !formulario) {
    
    return <div >

        <Breadcrumb items={miga} />
        <br></br>
        <Typography level={3} >Esta granja aún no tiene un formulario de caracterización</Typography>
        <img src={carpetaEliminar} width={100} height={100}/>
       
    </div>
  }

  return (
    <div>
      <Breadcrumb items={miga} />
      <br></br>
      <br></br>
      
      <Descriptions>
        <Descriptions.Item label="Título">
        {formulario?.titulo}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de creación">
        {dayjs(formulario?.fecha).format("YYYY/MM/DD hh:mm a")}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="IDENTIFICACIÓN DEL PISCICULTOR" layout="vertical">
        <Descriptions.Item label="Nombre y Apellido">
          {formulario?.nombre_apellido_piscicultor}
        </Descriptions.Item>
        <Descriptions.Item label="Documento identificación">
          {formulario?.numero_documento}
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          {formulario?.telefono_piscicultor}
        </Descriptions.Item>
        <Descriptions.Item label="Dirección de vivienda">
          {formulario?.direccion_de_vivienda}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="DIÁGNOSTICO" layout="vertical">
        <Descriptions.Item label="Asociación">
          {formulario?.empresa_pertenece || "Ninguna"}
        </Descriptions.Item>
        <Descriptions.Item label="Está interesado en hacer parte de una asociación">
          {formulario?.interesado_en_asociacion}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="INFORMACIÓN SOCIOECONÓMICA" layout="vertical">
        <Descriptions.Item label="Edad">{formulario?.edad}</Descriptions.Item>
        <Descriptions.Item label="Genero">
          {formulario?.Genero}
        </Descriptions.Item>
        <Descriptions.Item label="Estado civil">
          {formulario?.estado_civil}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo población">
          {formulario?.tipo_poblacion} {formulario?.tipo_poblacion_otro}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta con servicio de internet?">
          {formulario?.cuenta_servio_internet}
        </Descriptions.Item>
        <Descriptions.Item label="Escolaridad">
          {formulario?.escolaridad}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuál es el tiempo de dedicación a la actividad piscícola?">
          {formulario?.tiempo_dedicacion}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta con servicio de internet?">
          {formulario?.cuenta_servio_internet}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuál es el tiempo de dedicación a la actividad piscícola?">
          {formulario?.tiempo_dedicacion}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuánto tiempo tiene de experiencia en la actividad piscícola?">
          {formulario?.experiencia}
        </Descriptions.Item>
        <Descriptions.Item label="Formación en acuicultura/piscicultura">
          {formulario?.formacion_acuicultura}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="RESPONSABLE DEL CULTIVO" layout="vertical">
        <Descriptions.Item label="Nombre y Apellido">
          {formulario?.nombre_apellido_responsable}
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          {formulario?.telefono}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="DESCRIPCIÓN DEL PREDIO"
        layout="vertical"
      ></Descriptions>

      <Descriptions title="VOLUMEN DE AGUA" layout="vertical">
        <Descriptions.Item label="Volumen total de agua del sistema productivo (m3)">
          {formulario?.volumen_agua}
        </Descriptions.Item>
        <Descriptions.Item label="Volumen de agua en uso (m3)">
          {formulario?.agua_en_uso}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="CARACTERÍSTICAS DEL SISTEMA PRODUCTIVO"
        column={1}
        layout="vertical"
      >
        <Descriptions.Item label="Estanques">

        </Descriptions.Item>
      </Descriptions>
      <Table dataSource={estanques} pagination={false} bordered={false}>
        <Table.Column title="Estado" dataIndex={"estado_estanque"} />
       
        <Table.Column
          title="Capacidad de siembra"
          dataIndex="capacidad_de_siembra"
        />
        <Table.Column title="Forma" dataIndex="forma" />
        <Table.Column title="Dimensión (m)" render={(d)=>{
          if(d.forma=='Circular'){
              return d.diametro+' m'
          }
          if(['Rectangular','Cuadradado'].includes(d.forma)){
            return d.ancho+' x '+d.alto+' m '
          }
        }} />
        <Table.Column title="Material" dataIndex="material" />
        <Table.Column
          title="Sistema de aireación"
          dataIndex="sistema_aireacion"
        />
      </Table>
      <br></br>

      <Descriptions title="SISTEMAS DE PRODUCCIÓN"
        column={1}
        layout="vertical"
      >
        <Descriptions.Item label="">
          {formulario?.sistema_produccion?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="COMERCIALIZACIÓN" layout="vertical" column={2}>
        <Descriptions.Item label="Producción anual aproximada (Toneladas/por año)">{formulario?.produccion_aproximada_anual}</Descriptions.Item>
        <Descriptions.Item label="Clasificación del acuicultor según producción">
          {formulario?.clasificacion_del_acuicultor_segun_productor?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Como vende el producto">
          {formulario?.como_vende_producto?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.como_vende_producto_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuál es el tipo de comercialización?">
          {formulario?.cual_tipo_de_comercializacion?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿La empresa tiene vínculos formales para la comercialización del producto?">
          {formulario?.vehiculos_para_comercializar?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Lleva registros financieros de los gastos">
          {formulario?.lleva_registros_finacieros}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions layout="vertical" title="CARACTERIZACIÓN DE LAS ESPECIES CULTIVADAS" column={1}>

      </Descriptions>
      <Table size="small" dataSource={caracterizacion_de_las_especies_cultivadas} pagination={false}>
        <Table.Column title="Especie" dataIndex="nombre_pez" />
        <Table.Column title="Peso final" dataIndex="peso_final" />
        <Table.Column title="Meses de producción" dataIndex="meses" />
        <Table.Column title="Uso" dataIndex="uso" />
        <Table.Column title="Valor semilla" dataIndex="valor_semilla" />
        <Table.Column title="Tam. compra" dataIndex="tam_compra" />
        <Table.Column title="Proveedor de semilla" dataIndex="provedor_semilla" />
      </Table>
      <Descriptions layout="vertical" column={2}>
        <Descriptions.Item label="El proceso de sacrificio lo realiza mediante el uso de la técnica de:">
          {formulario?.proceso_sacrificio?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Cual">
          {formulario?.proceso_sacrificio_cual}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions layout="vertical" title="NUTRICIÓN ANIMAL">
        <Descriptions.Item label="¿Qué tipo de alimentación utiliza en su sistema productivo?">
          {formulario?.tipo_alimentacion_utilizada_sistema?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Qué marca de concentrado usa para la alimentación de los peces del cultivo?">
          {formulario?.marca_de_concentrado_usa_para_alimentacion?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Cual">
          {formulario?.marca_de_concentrado_usa_para_alimentacion_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuál es la razón por la que escoge la marca de concentrado?">
          {formulario?.razon_compra_concentrado?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Cual">
          {formulario?.razon_compra_concentrado_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Tiene alianza comercial para la compra del alimento?">
          {formulario?.tiene_alianza_comercial}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cómo calcula la ración diaria alimenticia que suministra a los peces?">
          {formulario?.raccion_diaria_alimneto?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Lleva registros del consumo de alimentación por ciclo?">
          {formulario?.lleva_registro_consumo}
        </Descriptions.Item>
        <Descriptions.Item label="¿Tiene bodega de almacenamiento de alimentos en buenas condiciones?">
          {formulario?.tiene_bodega}
        </Descriptions.Item>
        <Descriptions.Item label="¿Evidencia aprovechamiento total del alimento suministrado a los peces?">
          {formulario?.evidencia_arpovechamiento}
        </Descriptions.Item>
        <Descriptions.Item label="¿Suministra probióticos o suplementos durante la alimentación?">
          {formulario?.suministra_probioticos}
        </Descriptions.Item>
        <Descriptions.Item label="¿Conoce la relación entre las fases de crecimiento y el contenido proteico del alimento que debe suministrar al pez?">
          {formulario?.realcion_proteico_crecimineto}
        </Descriptions.Item>
        <Descriptions.Item label="¿Que porcentajes de proteína suministra a sus peces?">
          {formulario?.cantidad_proteina_suministrada?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Realiza biometrías">
          {formulario?.realiza_biometrias}
        </Descriptions.Item>
        <Descriptions.Item label="Lleva registros de indicadores técnicos de producción">
          {formulario?.lleva_registros_tecnicos}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.lleva_registros_tecnicos_cual}
        </Descriptions.Item>
        <Descriptions.Item label="Tasa de conversión alimenticia"></Descriptions.Item>
      </Descriptions>
      <Table pagination={false} dataSource={tasa}>
        <Table.Column title="TCA" dataIndex="tca" />
        <Table.Column title="Peso (g)/semanas" dataIndex="peso" />
      </Table>
      <br></br>
      <Descriptions layout="vertical" title="CALIDAD DE AGUA E IMPACTO AMBIENTAL">
        <Descriptions.Item label="¿De qué fuente proviene el agua que utiliza en el sistema productivo?">
          {formulario?.de_que_fuente_proviene?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.de_que_fuente_proviene_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Con qué frecuencia realiza las mediciones de los parámetros de calidad de agua?">
          {formulario?.con_que_frecuencia_medicion}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.con_que_frecuencia_medicion_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Qué parámetros de calidad de agua mide?">
          {formulario?.parametros_mide_cuales?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.parametros_mide_cuales_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Con qué frecuencia realiza recambio de agua?">
          {formulario?.con_que_frecuencia_realiza_cambio_agua}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.con_que_frecuencia_realiza_cambio_agua_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuál es el porcentaje (%) de recambio del agua?">
          {formulario?.porcentaje_cambio_agua}
        </Descriptions.Item>
        <Descriptions.Item label="¿Qué hace con el agua que sale del sistema productivo piscícola?">
          {formulario?.que_haces_con_agua_saliente?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.que_haces_con_agua_saliente_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Mide los parámetros fisicoquímicos y microbiológicos del agua que sale del sistema productivo piscícola?">
          {formulario?.mide_fisicoquimicos_microbiologicos_del_agua}
        </Descriptions.Item>
        <Descriptions.Item label="¿Retira el alimento sobrenadante y heces de los estanques?">
          {formulario?.retira_alimnetos_sobrenadante}
        </Descriptions.Item>
        <Descriptions.Item label="¿Realiza sifoneo?">
          {formulario?.realiza_sifoneo_}
        </Descriptions.Item>
        <Descriptions.Item label="¿Utiliza algún producto para mantener o mejorar la calidad del agua del sistema productivo piscícola?">
          {formulario?.producto_para_calidad_de_agua?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.producto_para_calidad_de_agua_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Tiene alguna especie no autorizada por la AUNAP?">
          {formulario?.alguna_especie_auorizada_unap?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.alguna_especie_auorizada_unap_cual}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta con medidas para evitar fugas de especies cultivadas?">
          {formulario?.medidas_contra_fugas}
        </Descriptions.Item>
        <Descriptions.Item label="¿Qué hace con los residuos pesqueros?">
          {formulario?.que_hace_con_residuos_pesqueros?.map(f => <Tag color="green">{f}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cual?">
          {formulario?.que_hace_con_residuos_pesqueros_cual}
        </Descriptions.Item>
        <Descriptions.Item label=" ¿Cuenta actualmente con el permiso de cultivo expedido por la AUNAP?">
          {formulario?.cuenta_permiso_unap}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta actualmente con el permiso para concesión de aguas?">
          {formulario?.cuenta_permiso_concepcion_de_aguas}
        </Descriptions.Item>

        <Descriptions.Item label="¿Cuenta con el certificado como establecimiento de Acuicultura Biosegura?">
          {formulario?.cuenta_certificado_establecimiento}
        </Descriptions.Item>
        <Descriptions.Item label="¿Realiza manejo y gestión de los residuos y de vertimientos generados en el sistema productivo piscícola?">
          {formulario?.manejo_gestion_residuos}
        </Descriptions.Item>

        <Descriptions.Item label="¿Cuenta con el programa de manejo de plagas y predadores?">
          {formulario?.manejo_plagas}
        </Descriptions.Item>
        <Descriptions.Item label="Cuenta con un Programa de limpieza y desinfección del sistema productivo?">
          {formulario?.programa_de_limpieza}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta con reservorio de agua?">
          {formulario?.reservorio_agua}
        </Descriptions.Item>
        <Descriptions.Item label="¿Cuenta con reservorio de agua?">
          {formulario?.PROYECCIÓN_DEL_PROYECTO}
        </Descriptions.Item>
        <Descriptions.Item label="¿cual?">
          {formulario?.PROYECCIÓN_DEL_PROYECTO_cual}
        </Descriptions.Item>
      </Descriptions>

      <div>
      <h3>Archivos</h3>
        {listarArchivos()}

      </div>


      <div>
        <h3>Firma productor</h3>
        <CampoFirma value={formulario?.firma_productor} />

      </div>
      <div>
        <h3>Firma asesor</h3>
        <CampoFirma value={formulario?.firma_asesor} />

      </div>

    </div>
  );
};

export default GranjaFormulario;
