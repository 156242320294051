import { Spin } from "antd"
import { useEffect, useState } from "react"
import useAuth from "../../Contextos/Auth/AuthContext"

const ValidarRol=({children,roles,callback})=>{
    const {user} = useAuth()
    const [cargando,setCargando] = useState(true)
    const [permitido,setPermitido] = useState(false)

    
    useEffect(()=>{
        if(user){
            if(roles.includes(user.rol)){
               setPermitido(true)
            }else{
                setPermitido(false)
            }
            setCargando(false)
        }
    },[user])
    
    useEffect(()=>{
        if(permitido && typeof callback == 'function'){
            callback()
        }
    },[permitido])
    
    if(cargando){
        return <Spin spinning={true}></Spin>
    }
    if(!user){
        return null
    }

 

    if(permitido){
        return children
    }

    return null
}

export default ValidarRol