import ReactEcharts from "echarts-for-react";
export const Parametro = ({ parametros,nombre,color }) => {
    
    const option = {
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: [nombre]
        },
        xAxis: {
            boundaryGap: false,
            type:'category',
            data: parametros?.map(e => e?.label)
        },
        yAxis: { type: 'value' },
        series: [
            {
                name: nombre,
                label: 'prueba',
                type: 'line',
                stack: 'Total',
                emphasis: {
                    focus: 'series'
                },
                data: parametros.map(e=>e?.value),
                color:color
            },
        ]
    };
    return <div>
        <ReactEcharts option={option} />

    </div>
}