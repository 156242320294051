import { Button, Form, Input, Modal, notification, Spin } from "antd"
import { useEffect, useState } from "react"
import API from "../../../API"
import { ASISTENTE } from "../../../Constantes"

const AsistenteGuardar = ({doc,setDoc,onGuardado}) => {
    const [cargando,setCargando] = useState(false)
    const [form] = Form.useForm()


    const guardar=async (payload)=>{
        try{
            setCargando(true)
            if(doc && doc?._id){
                await API.put('api/usuario/'+doc._id,{...payload,rol:ASISTENTE.value})
            }else{
                await API.post('api/usuario',{...payload,rol:ASISTENTE.value})
            }
            notification.success({message:'Asistente Guardado'})
            onGuardado()
        }catch(err){

        }finally{
            setCargando(false)
        }
    }

    useEffect(()=>{
        if(doc){
            form.setFieldsValue(doc)
        }
    },[doc])

    return <Modal open={doc} title="Guardar Asistente" cancelable={!cargando} footer={[]} onCancel={()=>setDoc(null)}>
        <Spin spinning={cargando}>
            <Form layout="vertical" form={form} onFinish={guardar}>
                <Form.Item label="Nombre" name="nombre" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Apellido" name="apellido" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Correo" name="correo" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input />
                </Form.Item>
                {!doc?._id && <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Campo requerido' }]}>
                    <Input.Password />
                </Form.Item>}
                <Button type="primary" htmlType="submit">Guardar</Button>
            </Form>
        </Spin>
    </Modal>
}

export default AsistenteGuardar