import { Form, Input, Modal, notification, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { message, Upload } from 'antd';
import Papa from 'papaparse'
import { InboxOutlined } from "@ant-design/icons"

import useBaseDatos from "../../../Ganchos/BaseDatos"
import { TIPO_GRANJA, TIPO_ESTANQUE, TIPO_PARAMETROS } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes";
import useAuth from "../../../Contextos/Auth/AuthContext";
import { Parametros } from "../../listar";
import dayjs from "dayjs";


export const Precargar = ({ element, setParametro, alGuardar }) => {
    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
           
            const txt = file.type === 'text/plain';
            if (!txt) {
                message.error('solo se aceptan archivos TXT');
                return true
            } else {
                // console.log('llego acasssss.--------')
                // leerTxt({file})

            }
            return false
        },
        onChange: ((file) => leerTxt(file))
    }
    const { base_de_datos } = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const [granjas, setGranjas] = useState([])
    const [parametrosCargados, setparametrosCargados] = useState([])
    const [estanque, setEstanque] = useState([])
    const user = useAuth()
    const granjas_ = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_GRANJA } })
        setGranjas(data?.docs)
    }
    const estanques_ = async (id) => {
      
        const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE, id_granja: id } })
        setEstanque(data?.docs)
    }
    const guardar = async (payload) => {
        try {
            setCargando(true)
            let info = null

            const fecha = new Date();
            const añoActual = fecha.getFullYear();
           
            const productor = user.user.rol == PRODUCTOR.value ? user.user._id : user.user.productor
           
            if (parametrosCargados.length > 0) {
                parametrosCargados.forEach((element, index) => {                 

                    if (index > 0) {                       

                        info = base_de_datos.post(
                            {
                                estanque: payload.estanque,
                                granja: payload.granja,
                                type: TIPO_PARAMETROS,
                                anio_creacion: añoActual,
                                usuario: user.user._id,
                                productor: productor,
                                fecha: new Date(element.fecha),
                                temperatura: element.temperatura,
                                amonio:0,
                                oxigeno:0,
                                ph: element.ph,
                                tds: element.tds,
                                os: element.os,
                                o20: element.o20,
                                rs232: element.rs232

                            })
                    }
                })
                notification.success({ message: 'Parametros guardos' })


            }
            // info = await base_de_datos.post({ ...payload, type: TIPO_PARAMETROS, anio_creacion: añoActual, usuario: user.user._id, productor: productor })

            
            notification.success({ message: 'Parametros guardada' })

            alGuardar()
            setParametro(null)

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)

        }

    }
    const leerTxt = (event) => {
        try {
           
            const file = event.fileList[0]

            const lector = new FileReader();
            const arrayOrganizado = []
            lector.onload = (eventoLector) => {

                const txt = Papa.parse(eventoLector.target.result, { header: false, delimiter: ',' })

                const parsedData = txt?.data;

                parsedData.forEach(element => {
                    if(element.length>7){
                        arrayOrganizado.push({ fecha: `${element[0]} ${element[1]}`, temperatura: element[2], ph: element[3], tds: element[4], os: element[5], o20: element[6], rs232: element[7] })
                    }
                });

                
                setparametrosCargados(arrayOrganizado)

            }

            lector.readAsText(file.originFileObj)

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        granjas_()
    }, [])
    return <Modal open={element} title='Guardar Parámetros' closable={!cargando} onCancel={() => setParametro(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={guardar}>
            <Form.Item label="Granja" name='granja' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Select options={granjas?.map((granja, index) => ({ value: granja._id, label: granja.nombre }))} onChange={estanques_} />
            </Form.Item>
            <Form.Item label="Estanque" name='estanque' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Select options={estanque?.map((est, index) => ({ value: est._id, label: est.nombre }))} />
            </Form.Item>
            <Form.Item label="archivo" name='archivo' >
                <Dragger {...props} maxCount={1}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">AGREGA LOS ARCHIVOS A ENVIAR DENTRO DE ESTA AREA</p>
                    <p className="ant-upload-hint">
                        carga los archivos de forma manual aca
                    </p>
                </Dragger>
            </Form.Item>

        </Form>
    </Modal>
}