import { Breadcrumb, Button, Col, DatePicker, Empty, Form, Input, Row, Select, Table, Tag } from "antd";
import Card from "antd/es/card/Card"

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../Contextos/Auth/AuthContext";
import { colombianPeso_, PRODUCTOR } from "../../Constantes";
import API from "../../API";
import { TIPO_CICLOS, TIPO_ESTANQUE, TIPO_GRANJA, TIPO_INSUMOS } from "../../Base/Contantes";
import useBaseDatos from "../../Ganchos/BaseDatos";

export const Reporte = () => {

    const { user } = useAuth()
    const [granja, setGranjas] = useState([])
    const [productor, setProductor] = useState([])
    const [estanque, setEstanque] = useState([])
    const [insumos, setInsumos] = useState([])
    const [ciclos, setCiclos] = useState([])
    const [form] = Form.useForm()
    const [columnas,setColumnas] = useState([])
    const [datos,setDatos] = useState([])
    const [CICLOS,setCICLOS] = useState({})

    const { base_de_datos } = useBaseDatos()
   
    const renderPeso=(v)=>{
        return colombianPeso_.format(v || 0)
    }

    const generarDatosDelCiclo = ({ciclo}) => {
        let _columas = [
            {
                title:'Insumo',
                dataIndex:'insumo'
            }
        ]
        const CICLO = ciclos.find(e=>e?._id === ciclo)
        const _datos=[]
        setCICLOS(CICLO)
   
        let diff = Math.ceil(dayjs(CICLO?.fecha_final).diff(dayjs(CICLO?.fecha_inicio),'month',true))
      
        if (diff==0){
            const fechas = CICLO.insumos.map(insumo => dayjs(insumo.fecha_insumo));
          
            const fechaMayor = fechas.reduce((maxFecha, fecha) => (fecha.isAfter(maxFecha) ? fecha : maxFecha));
            
            diff = Math.ceil(dayjs(fechaMayor).diff(dayjs(CICLO?.fecha_inicio),'month',true))
           
        }
        let meses=[
            dayjs(CICLO?.fecha_inicio)
        ]
       

        _columas.push({
            title:meses[0].format("YYYY MMM"),
            dataIndex:meses[0].format("YYYYMM"),
            align:'right',
            render:renderPeso
        })
       
        
        for(let i = 1;i<=diff;i++){
            const mes=meses[i-1].clone().add(1,'month')
           
            
            meses.push(
                mes
            )
            _columas.push({
                title:mes.format("YYYY MMM"),
                dataIndex:mes.format("YYYYMM"),
                render:renderPeso
            })
        }
        
        

        insumos.forEach((insumo)=>{

            const registro={
                insumo:insumo?.nombre_insumo
            }

            CICLO?.insumos.filter(i=>i.insumo==insumo._id)?.forEach((insumo_del_ciclo)=>{
                const fecha_insumo=dayjs(insumo_del_ciclo.fecha_insumo).format("YYYYMM")
                if(!registro[fecha_insumo]){
                    registro[fecha_insumo]=0
                }

                registro[fecha_insumo]+=parseFloat(insumo_del_ciclo.total)
            })

            _datos.push(registro)
        })

        _datos.forEach((fila)=>{
            let total = Object.keys(fila).reduce((a,b)=>{
                if(fila[b]>0){
                    return a+fila[b]
                }
                return a
            },0)
            fila.total = total
        })

        _columas.push({title:'Valor Total',dataIndex:'total',align:'right',render:renderPeso})

        console.log(_columas,"esta son las columnas")
       
        setColumnas(_columas)
        setDatos(_datos)

    };

    const onChangeEstanque = (value) => {

        cargar_estanque(value)
    };

    const cargarCiclos = async(id)=>{
        try {
            const data = await base_de_datos.find({ selector: { type: TIPO_CICLOS ,estanque:id }})
            setCiclos(data.docs)
           
        } catch (error) {
            console.log(error)
        }
    }

    const traerDatos = async () => {
        try {
           
            const { data } = await API('api/usuario/listar')
            
            if (user.rol === PRODUCTOR.value) {
                let array_user=[]
                array_user.push({
                   nombre:user.nombre,
                   _id :user._id
                })
                setProductor(array_user)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const cargar_granjas = async () => {
        try {
            const id=user._id
            const data = await base_de_datos.find({ selector: { type: TIPO_GRANJA } })
         
            setGranjas(data.docs)

        } catch (error) {
            console.log(error)
        }
    }

    const cargar_estanque = async ({estanque}) => {
        try {
            const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE ,id_estaque:estanque }})
            setEstanque(data.docs)
        } catch (error) {
            console.log(error)
        }
    }

    const cargarEstanques=async (id_granja)=>{
        try {
            form.setFieldValue('estanque','')
            form.setFieldValue('ciclo','')
            setCiclos([])
            setEstanque([])
            console.log(id_granja)
            const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE,id_granja }})
            setEstanque(data.docs)
        } catch (error) {
            console.log(error)
        }
    }

    const listarInsumos = async (id) => {
        try {
            const _insumos = await base_de_datos.find({selector:{type:TIPO_INSUMOS}})
            setInsumos(_insumos?.docs)
        } catch (error) {
            console.log(error, "error -----")
        }

    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const generar = async (payload) => {
        try {
          
            Object.keys(payload).forEach((k)=>{
                if(!payload[k]){
                    delete payload[k]
                }
            })
            generarDatosDelCiclo(payload)
            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.resetFields()
        setDatos([])
        traerDatos()
        listarInsumos()
        cargar_granjas()
    }, [])

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const numero_de_columnas = Object.keys(columnas).length
  

    return <div style={{ padding: 32 }}>

        <Breadcrumb items={[
                    { title: <Link to='/'>Inicio</Link> },
                    {title: "Reporte" },
                   
                ]} />

   
                <Card>
                    <Form layout="vertical" form={form} onFinish={generar}>

                        <Row gutter={16}>
                            <Col xs={24} md={4}>
                                <Form.Item name="granja" label="Granja" rules={[{required:true,message:'Campo requerido'}]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Granja"
                                        optionFilterProp="children"
                                        onChange={cargarEstanques}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={granja.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="estanque" label="estanque" rules={[{required:true,message:'Campo requerido'}]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Estanque"
                                        optionFilterProp="children"
                                        onChange={cargarCiclos}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={estanque.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="ciclo" label="Ciclo" rules={[{required:true,message:'Campo requerido'}]}>
                                    <Select
                                        showSearch
                                        filterOption={filterOption}
                                        options={ciclos?.map((e)=>({value:e?._id,label:e?.nombre_ciclo}))}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={4}>
                                <Button type="primary" style={{backgroundColor:"#81BD00",marginTop:30}} htmlType="submit" >Filtrar</Button>
                            </Col> */}
                            <br></br>
                            <br></br>
                            <br></br>
                        </Row>
                        <Button htmlType="submit" type="primary" style={{ backgroundColor: "#81BD00" }}>Generar reporte</Button>
                    </Form>
                    <br></br>
                    {CICLOS?._id && <p>El ciclo productivo <b>{CICLOS?.nombre_ciclo}</b> abarca el periodo <b>{dayjs(CICLOS?.fecha_inicio).format("YY MMMM")}</b> A <b>{CICLOS?.fecha_final && CICLOS?.fecha_final!='' ? dayjs(CICLOS?.fecha_final).format("YY MMMM") : 'Indefinido'}</b></p>}
                  
                            <Table
                                pagination={false}
                                bordered={false}
                                dataSource={datos}
                                rowKey={'_id'}
                                scroll={
                                    { x:500,
                                     y:600,}
                                }
                                columns={columnas}

                                summary={()=>{
                                    if(!datos.length){
                                        return null
                                    }
                                    return <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell index={0} align='right' colSpan={numero_de_columnas-1}>Costo Total de Producción</Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align='right'>{colombianPeso_.format(datos.reduce((a,b)=>a+b?.total,0))}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={numero_de_columnas-1} index={2} align='right' >Peso promedio</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} align='right' >{CICLOS.peso_promedio_peces} kg</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={3} align='right' colSpan={numero_de_columnas-1}>Margen de Utilidad ponderada</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align='right' >{CICLOS.margen_utilidad_ponderado} %</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={3} align='right' colSpan={numero_de_columnas-1}>Costo de producción por pez</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align='right' >$ {colombianPeso_.format((datos.reduce((a,b)=>a+b?.total,0))/CICLOS.cantidad_peses)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={3} align='right' colSpan={numero_de_columnas-1}>Costo de producción por kg</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align='right' >$ {colombianPeso_.format((datos.reduce((a,b)=>a+b?.total,0))/CICLOS.peso_promedio_peces)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={3} align='right' colSpan={numero_de_columnas-1}>Precio de venta sugerido</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align='right' ><Tag color="green">$ {colombianPeso_.format(((datos.reduce((a,b)=>a+b?.total,0))/CICLOS.peso_promedio_peces)/CICLOS.margen_utilidad_ponderado)}</Tag></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                }}
                            >
                            </Table>

                </Card>
      

    </div>
}