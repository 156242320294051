import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Form, Popconfirm, Row, Select, Space, Table, DatePicker, Card, Tooltip, Popover } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { TIPO_ESTANQUE, TIPO_GRANJA, TIPO_PRODUCCION } from "../../../Base/Contantes"
import { ASISTENTE, PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import { ProduccionGuardar } from "../Guardar"
import dayjs from 'dayjs'
import Papa from 'papaparse'
import produccion_imagen from"../../../static/estanques_.png"
export const Producciones = () => {
    const [producciones, setProducciones] = useState([])
    const { base_de_datos } = useBaseDatos()
    const [produccion, setProduccion] = useState(null)
    const [selectproduccion, setSelecproduccion] = useState([])
    const [form] = Form.useForm()
    const [filtrado, setFiltrado] = useState(false)
    const user = useAuth()
    const [granjas, setGranjas] = useState([])
    const [estanqueglobales, setEstanqueGlobales] = useState([])


    const cargar = async (filtros = {}) => {

        if (user.user.rol == ASISTENTE.value) {
            const data = await base_de_datos?.find({ selector: { type: TIPO_PRODUCCION, usuario: user.user._id, ...filtros } })

            setProducciones(data?.docs)
        }

        if (user.user.rol == PRODUCTOR.value) {
            const data = await base_de_datos?.find({ selector: { type: TIPO_PRODUCCION, ...filtros } })

            setProducciones(data.docs)

        }
    }

    //------------------- csv -----------------------------------------------------------
    const cargar_granjas = async () => {

        const data = await base_de_datos?.find({ selector: { type: TIPO_GRANJA } })

        setGranjas(data?.docs)

    }
    const Estanque_globales = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE } })

        setEstanqueGlobales(data?.docs)
    }
    const DescargarCSV =  () => {
        console.log(producciones,"esta es produccion")
        const dataCSV =  producciones?.map(e => ( {           
                granja:granjas.find(g=>g._id==e.granja).nombre,
                estanque:estanqueglobales.find(g=>g._id==e.estanque).nombre,
                alimento_real_consumido: e?.alimento_real_consumido,
                biomasa_final: e?.biomasa_final,
                biomasa_inicial: e?.biomasa_inicial,
                biomasa_promedio: e?.biomasa_promedio,
                cantidad_alimento_periodo: e?.cantidad_alimento_periodo,
                cantidad_peses: e?.cantidad_peses,
                cantidad_peses_muertos: e?.cantidad_peses_muertos,
                dias_periodo: e?.dias_periodo,
                factor_conversion:e?.factor_conversion,
                temperfecha_muestreoatura:e?.fecha_muestreo, 
                incremento_biomasa:e?.incremento_biomasa,
                peso_promedio_peses:e?.peso_promedio_peses ,  
                peso_total_peces_capturados:e?.peso_total_peces_capturados,
                racion_diaria:e?.racion_diaria ,  
                tiempo_cultivo:e?.tiempo_cultivo,              
            }
        ))
        console.log(dataCSV, "csv")
        const csv = Papa.unparse(dataCSV)
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Parámetros.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //-----------------------------------------------------------------------------------



    const filtrado_produccion = (payload) => {

        cargar({
            fecha_muestreo: {
                $gte: payload.fechas[0].toDate(),
                $lte: payload.fechas[1].toDate(),
            }
        })
    }

    const borrar = async (doc) => {

        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        form.submit()
    }

    useEffect(() => {
        form.setFieldValue('fechas',[dayjs().subtract(6,'month'),dayjs()])
        form.submit()
    }, [base_de_datos])

    useEffect(() => {
        cargar_granjas()
        Estanque_globales()
    }, [])


    return <div>
        <Space size={72}>
            <Breadcrumb items={[{ title: <Link to='/' >inicio</Link> }, { title: 'INDICADORES TÉCNICOS DE PRODUCCIÓN PISCÍCOLA' }]} />
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setProduccion({})}>Añadir registro</Button>
            <Button type="primary" htmlType="submit" onClick={() => DescargarCSV()} style={{ backgroundColor: "#81BD00" }}>Descargar CSV</Button>

        </Space>
        <br></br>
        <br></br>
        <Card>
            <Form layout="vertical" form={form} onFinish={filtrado_produccion}>
                <Row gutter={10}>
                    <Col sm={24} md={8}>
                        <Form.Item name="fechas" label="Rango de fechas" rules={[{ required: true, message: 'Seleccione un rango de fechas', },]}>
                            <DatePicker.RangePicker />
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                        <Form.Item noStyle>
                            <Button type="primary" disabled={filtrado} style={{ backgroundColor: "#81BD00", marginTop: 28 }} htmlType="submit" >Filtrar</Button>
                        </Form.Item>
                    </Col>
                    {/* <Col sm={24} md={8}>
                    <Form.Item noStyle>
                        <Button type="primary" style={{ backgroundColor: "#81BD00" }} onClick={limpiar} htmlType="button" >Limpiar Filtro</Button>
                    </Form.Item>
                </Col> */}
                </Row>
            </Form>
        </Card>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra tu producción" title="Módulo de producción" trigger='click'>
                <img width={128} src={produccion_imagen} />
            </Popover>
        </div>
        <ProduccionGuardar produccion={produccion} setProduccion={setProduccion} alGuardar={cargar} />

        <Table
            dataSource={producciones}
            rowKey='_id'
            pagination={false}
            scroll={{ x: 600 }}
            size='small'
        >
            <Table.Column title='fecha muestreo' width='30' dataIndex={'fecha_muestreo'} render={(e) => dayjs(e).format('DD-MM-YYYY')} />
            <Table.Column title='tiempo cultivo (Días)' dataIndex={'tiempo_cultivo'} />
            <Table.Column title='No. Dias del periodo' dataIndex={'dias_periodo'} />
            <Table.Column title='No. de peces ' dataIndex={'cantidad_peses'} />
            <Table.Column title='Peces muertos en el periodo' dataIndex={'cantidad_peses_muertos'} />
            <Table.Column title='Peso promedio(gr)' dataIndex={'peso_promedio_peses'} />
            <Table.Column title='Biomasa Promedio(Kg)' dataIndex={'biomasa_promedio'} />
            <Table.Column title='Incremento Biomasa(Kg)' dataIndex={'incremento_biomasa'} />
            <Table.Column title='Alimento consumido' dataIndex={'alimento_real_consumido'} />
            <Table.Column title='Tasa de alimentación ' dataIndex={'tasa_alimentacion'} />
            <Table.Column title='Ración diaria (kg)' dataIndex={'racion_diaria'} />
            <Table.Column title='Cantidad alimento consumido periodo(kg)' dataIndex={'cantidad_alimento_periodo'} />
            {/* <Table.Column title='Cantidad alimento real consumido periodo(kg)'/> */}
            <Table.Column title='Factor de conversión alimenticia (FCA)' dataIndex={'factor_conversion'} />


            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title='Editar'>
                    <Button icon={<EditOutlined />} shape='circle' onClick={() => setProduccion(doc)}></Button>
                </Tooltip>
                <Tooltip title='Eliminar'>
                    <Popconfirm title="Se borrar la granja y sus estantes" onConfirm={() => borrar(doc)}>
                        <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                    </Popconfirm>
                </Tooltip>
            </Space>} />
        </Table>
    </div>
}