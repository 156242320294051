import { Button, Form, Input, Typography, notification } from "antd"
import useBaseDatos from "../../../../Ganchos/BaseDatos"
import { useState,useEffect } from "react"

const Dispositivo=({id_estanque})=>{
    const {base_de_datos} = useBaseDatos()
    const [estanque,setEstanque]=useState(null)
    
    const guardar = async ({mac})=>{
        try{
            const d =await base_de_datos.get(id_estanque)
            await base_de_datos.put({...d,mac})
            cargarEstanque()
        }catch(err){
            notification.error({message:'Dispositivo vinculado',description:err.toString()})
        }finally{

        }
    }

    const desvincular=async ()=>{
        try{
            const {mac,...resto} =await base_de_datos.get(id_estanque)
            await base_de_datos.put({...resto})
            cargarEstanque()
        }catch(err){
            notification.error({message:'Dispositivo desvinculado',description:err.toString()})
        }finally{

        }
    }

    const cargarEstanque=async ()=>{
        const d =await base_de_datos.get(id_estanque)
        setEstanque(d)
    }

    useEffect(()=>{
        if(id_estanque){
            cargarEstanque()
        }
    },[id_estanque])

    if(!estanque?.mac){
        return <div>
            <Typography.Paragraph>Este estanque no tiene un dispositivo configurado</Typography.Paragraph>
            <Form layout="vertical" onFinish={guardar}>
                    <Form.Item label="Identificador" name={'mac'} rules={[{required:true,message:'Ingrese el ID del dispositivo'}]}>
                        <Input/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Vincular</Button>
            </Form>
        </div>
    }

    return <div>
        <Typography.Text>Dispositivo</Typography.Text>
        <Typography.Title>{estanque?.mac}</Typography.Title>
        <Button danger onClick={desvincular} >Desvincular</Button>
    </div>
}

export default Dispositivo