import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Card, Col, Popconfirm, Row, Space, Table, Typography } from "antd"
import { Link } from "react-router-dom"


export const SelectProductor = () => {
    const productores = [
        {
            nombre: "nombre",
            apellido: "apelido",
            correo: "correo"
        },
        {
            nombre: "nombre 2",
            apellido: "apelido 2",
            correo: "correo 2"
        }
    ]
    return <div>
        <Breadcrumb items={[{ title: <Link to='/'>Inicio</Link> },{ title: 'Productores' }]} />
        <br></br>
        <Table dataSource={productores} rowKey='_id' pagination={false}>
            <Table.Column title='Nombre' dataIndex={'nombre'}/>
            <Table.Column title='Apellido' dataIndex={'apellido'} />
            <Table.Column title='Correo' dataIndex={'correo'} />
            <Table.Column title="Opciones" render={(doc)=><Space size={24}>
               
                <Link to={`/granja/${doc._id}/estanques`}><Button shape='circle' icon={<img src='https://cdn-icons-png.flaticon.com/512/12461/12461920.png ' width={24}/>}></Button></Link>
                
            </Space>} />
        </Table>
    </div>
}