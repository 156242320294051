import { Breadcrumb, Button, Popconfirm, Popover, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { EditOutlined, DeleteOutlined, PlusOutlined, AuditOutlined } from "@ant-design/icons"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import GranjaGuardar from "../Guardar"
import { TIPO_GRANJA } from "../../../Base/Contantes"
import estan from "../../../static/estanque.png"
import img_granja from"../../../static/granero.png"
const GranjaListado = () => {
    const { base_de_datos } = useBaseDatos()
    const [granjas, setGranjas] = useState([])
    const [granja, setGranja] = useState(null)


    const cargar = async () => {

        const data = await base_de_datos?.find({ selector: { type: TIPO_GRANJA } })

        setGranjas(data?.docs)
    }

    const borrar = async (doc) => {

        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    useEffect(() => {
        cargar()
    }, [base_de_datos])

    return <div>
        <Space size={32}>
            <Breadcrumb items={[{ title: <Link to='/' >Inicio</Link> }, { title: 'Granjas' }]} />
            <Button icon={<PlusOutlined />} type="link"  onClick={() => setGranja({})} >Añadir granja</Button>
        </Space>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra los granjas para que puedas trabajar con ellas mas adelante" title="Módulo Granjas" trigger='click'>
                <img width={128} src={img_granja} />
            </Popover>
        </div>
        <GranjaGuardar granja={granja} setGranja={setGranja} alGuardar={cargar} />

        <Table
            dataSource={granjas}
            rowKey='_id'
            pagination={false}
            scroll={
                {
                    x: 500,
                    y: 400
                }
            }>
            <Table.Column title='Nombre' dataIndex={'nombre'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title='Ver formulario de la granja'>
                    <Link to={`/granja/${doc._id}/formulario`}>
                        <Button icon={<AuditOutlined />} shape='circle'></Button>
                    </Link>
                </Tooltip>
                <Tooltip title={'Editar'}>
                    <Button onClick={() => setGranja(doc)} icon={<EditOutlined />} shape='circle'></Button>
                </Tooltip>
                <Tooltip title={'Ver estanques'}>
                    <Link to={`/granja/${doc._id}/estanques`}><Button shape='circle' icon={<img src={estan} width={24} />}></Button></Link>
                </Tooltip>
                <Popconfirm title="Se borrar la granja y sus estantes" onConfirm={() => borrar(doc)}>
                    <Tooltip title={'Eliminar'}>
                        <Button shape="circle" danger icon={<DeleteOutlined />}></Button>
                    </Tooltip>
                </Popconfirm>
            </Space>} />
        </Table>
    </div>
}

export default GranjaListado