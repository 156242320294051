import { Form, Input, Modal, notification, Select, InputNumber, DatePicker, Row, Col ,Divider} from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { TIPO_ESTANQUE, TIPO_GRANJA, TIPO_PARAMETROS } from "../../Base/Contantes"
import { PRODUCTOR } from "../../Constantes"
import useAuth from "../../Contextos/Auth/AuthContext"
import { Upload } from 'antd';
import dayjs from "dayjs"

import useBaseDatos from "../../Ganchos/BaseDatos"


export const ParametrosGuardar = ({ parametro, setParametro, alGuardar }) => {
    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: false,
        beforeUpload: () => {
            return false
        }
    }
    const { base_de_datos } = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const [granjas, setGranjas] = useState([])
    const [parametrosCargados, setparametrosCargados] = useState([])
    const [estanque, setEstanque] = useState([])
    const user = useAuth()

    const granjas_ = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_GRANJA } })
        setGranjas(data?.docs)
    }

    const estanques_ = async (id) => {
        
        const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE, id_granja: id } })
        setEstanque(data?.docs)
    }

    const guardar = async (payload) => {
        try {
            setCargando(true)
            let info = null
            const fecha = payload?.fecha?.toDate()

            if (parametro._id) {
                info = await base_de_datos.put({ ...parametro, ...payload, fecha })
                notification.success({ message: 'Estanque actualizado' })
                return
            } else {
                const fecha = new Date();
                const anio_creacion = fecha.getFullYear();
                const productor = user.user.rol == PRODUCTOR.value ? user.user._id : user.user.productor

                info = await base_de_datos.post({
                    ...payload,
                    type: TIPO_PARAMETROS,
                    anio_creacion,
                    usuario: user.user._id,
                    productor: productor,
                    fecha
                })
            }
           
            notification.success({ message: 'Estanque guardada' })
            setParametro(null)
            alGuardar()

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }

    useEffect(() => {
        if (parametro) {
            const fecha = dayjs(parametro?.fecha)
            form.setFieldsValue({ ...parametro, fecha })
            granjas_()
            estanques_()
        } else {
            form.resetFields()
        }
    }, [parametro])

    return <Modal open={parametro} title='Registrar Parámetros y mediciones'  style={{body:{padding:0,margin:0}}} closable={!cargando} onCancel={() => setParametro(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={guardar}>

            <div style={{marginTop:32}}>
            <Form.Item label="Fecha en que se tomó la muestra" name='fecha' rules={[{ required: false, message: 'Campo requerido' }]} >
                <DatePicker showTime />
            </Form.Item>
            </div>
            <Divider/>

            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Form.Item label="Granja" name='granja' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select style={{ width: '100%' }} options={granjas?.map((granja, index) => ({ value: granja._id, label: granja.nombre }))} onChange={estanques_} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Estanque" name='estanque' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select style={{ width: '100%' }} options={estanque?.map((est, index) => ({ value: est._id, label: est.nombre }))} />
                    </Form.Item>
                </Col>
            </Row>

            <div style={{background:'#F8F9F9',padding:8}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <Form.Item label="Temp. °C" name='temperatura' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={6}>
                        <Form.Item label="Amonio" name='amonio' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="oxigeno" name='oxigeno' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="PH" name='ph' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="TDS" name='tds' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="OS" name='os' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="O20" name='o20' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="RS232" name='rs232' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Nitrito" name='nitrito' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label="Nitrato" name='nitrato' rules={[{ required: false, message: 'Campo requerido' }]} >
                            <InputNumber style={{ width: '100%' }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </div>

        </Form>
    </Modal>
}

