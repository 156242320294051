import { Form, Input, Modal, notification } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TIPO_INSUMOS } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"

export const InsumoGuardar = ({ insumo, setInsumo, alGuardar }) => {
    const { base_de_datos } = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const user=useAuth()
    const guardar = async (payload) => {
        try {
            setCargando(true)
            let info = null
            if (insumo._id) {
                info = await base_de_datos.put({ ...insumo, ...payload })
            } else {
                const fecha = new Date();
                const anio = fecha.getFullYear();
                const productor=user.user.rol==PRODUCTOR.value?user.user._id:user.user.productor
                info = await base_de_datos.post({ ...payload, type: TIPO_INSUMOS ,anio_creacion:anio,usuario:user.user._id,productor:productor})
            }
            console.log(info)
            notification.success({ message: 'Insumo guardado' })
            setInsumo(null)
            alGuardar()

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }
    useEffect(() => {
        if (insumo) {
            form.setFieldsValue(insumo)
        } else {
            form.resetFields()
        }
    }, [insumo])

    return <Modal open={insumo} title='Guardar Insumo' closable={!cargando} onCancel={() => setInsumo(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={guardar}>
            <Form.Item label="nombre" name='nombre_insumo' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>
        </Form>
    </Modal>
}