import { Form, Input, Modal, notification, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { TIPO_GRANJA } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"

const GranjaGuardar=({granja,setGranja,alGuardar})=>{
    const {base_de_datos}=useBaseDatos()
    const [cargando,setCargando] = useState(false)
    const user=useAuth()
    const [form] = useForm()

    const guardar=async (payload)=>{
        try{
            setCargando(true)
            let info=null
            if(granja._id){
                info  = await base_de_datos.put({...granja,...payload})
            }else{
                const fecha = new Date();
                const añoActual = fecha.getFullYear();
                const productor=user.user.rol==PRODUCTOR.value?user.user._id:user.user.productor 
                info = await base_de_datos.post({...payload,type:TIPO_GRANJA,anio_creacion:añoActual,usuario:user.user._id,productor:productor})
            }
           
            notification.success({message:'Granja guardada'})
            setGranja(null)
            alGuardar()
        }catch(error){
            notification.error({message:error.toString()})
        }finally{
            setCargando(false)
        }
      
    }

    useEffect(()=>{
        if(granja){
            form.setFieldsValue(granja)
        }else{
            form.resetFields()
        }
    },[granja])

    
    return <Modal open={granja} title='Guardar granja'  closable={!cargando} onCancel={()=>setGranja(null)} okText='Guardar' cancelButtonProps={{style:{display:'none'}}} onOk={()=>form.submit()}>
            <Form form={form} layout="vertical" onFinish={guardar}>
                <Form.Item label="Nombre" name='nombre' rules={[{required:true,message:'Campo requerido'}]} >
                    <Input/>
                </Form.Item>
                
            </Form>
    </Modal>
}

export default GranjaGuardar