import { Switch, notification } from "antd"
import { useState } from "react"
import API from "../../../API"

const ProductorEstado =({_id,activo,onEstado})=>{
    const [cargando,setCargando] = useState(false)

    const inactivar=async ()=>{
        try{
            setCargando(true)
            await API.delete('api/productor/'+_id)
            onEstado()
        }catch(err){
            notification.error({message:err.toString()})
        }finally{
            setCargando(false)
        }
    }

    const activar=async ()=>{
        try{
            setCargando(true)
            await API.post('api/productor/'+_id+'/activar')
            onEstado()
        }catch(err){
            notification.error({message:err.toString()})
        }finally{
            setCargando(false)
        }
    }

    const actualizarEstado=(estado)=>{
        if(estado){
            activar()
        }else{
            inactivar()
        }
    }

    return <div>
        <Switch loading={cargando} checked={activo} onChange={actualizarEstado}/>
    </div>
}

export default ProductorEstado