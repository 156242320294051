import { Result,Button } from "antd"
import { Link } from "react-router-dom"

const PaginaNoEncontrada=()=>{
    return <Result
        status={404}
        title='Página no encontrada'
        subTitle=''
        extra={<Link to='/'><Button type="primary">Regresar al inicio</Button></Link>}
    />
}

export default PaginaNoEncontrada