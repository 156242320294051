import { DeleteOutlined } from '@ant-design/icons'
import { Modal, Form, Row, Col, Button, Input, Select, notification,InputNumber } from 'antd'
import { useEffect, useState } from 'react'
import { TIPO_INSUMOS } from '../../../../Base/Contantes'
import useBaseDatos from '../../../../Ganchos/BaseDatos'

const Gastos = ({ doc, onCancelGasto, ciclo }) => {
   
    const [cargando, setCargando] = useState(false)
    const { base_de_datos } = useBaseDatos()
    const [form] = Form.useForm()
    const [insumos_, setinsumos] = useState([])

    const insumo = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_INSUMOS } })
        setinsumos(data?.docs)
    }

    const guardar = async (payload) => {
        try {
            setCargando(true)
            let info = null
            if (doc._id) {
                info = await base_de_datos.put({ ...doc, ...payload })
                notification.success({ message: 'gasto ingresado' })
                onCancelGasto(null)
            }
            // } else {
            //     const fecha = new Date();
            //     const añoActual = fecha.getFullYear();
            //    
            //     const productor=user.user.rol==PRODUCTOR.value?user.user._id:user.user.productor
            //     info = await base_de_datos.post({ ...payload, type: TIPO_CICLOS ,anio_creacion:añoActual,productor:productor,usuario:user.user._id})
            // }
            // console.log(info)
            // notification.success({ message: 'Estanque guardada' })
            // setCiclo(null)
            // alGuardar()

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }

    useEffect(() => {
        if (doc) {
            form.setFieldsValue({ insumos: doc.insumos })
        } else {
            form.resetFields()
        }
    }, [doc])

    useEffect(() => {
        insumo()
    }, [])

    return <Modal Modal open={doc} width={800} title='Registrar Gasto' closable={!cargando} onCancel={onCancelGasto} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <br></br>
        <br></br>
        <Form layout='vertical' form={form} onFinish={guardar}>
            <Form.List
                name='insumos'
            >

                {(insumos, { add, remove }) => (
                    <div>
                        {insumos.map((field, i) => (
                            <div key={i}>
                                <Row gutter={[16,16]}>
                                    <Col sm={24} md={10}>
                                        <Form.Item name={[field.name, 'insumo']} label='Insumo' rules={[{ required: true, message: 'Campo requerido' }]}>
                                            <Select style={{width:'100%'}} readOnly={doc.estado==0} options={insumos_?.map((insumo, index) => ({ value: insumo._id, label: insumo.nombre_insumo }))} />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={6}>
                                        <Form.Item name={[field.name, 'total']} label='$ Total' rules={[{ required: true, message: 'Campo requerido' }]}>
                                            <InputNumber style={{width:'100%'}} readOnly={doc.estado==0}/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={6}>
                                        <Form.Item name={[field.name, 'fecha_insumo']} label='Fecha' rules={[{ required: true, message: 'Campo requerido' }]}>
                                            <Input type="date" style={{width:'100%'}} readOnly={doc.estado==0} />
                                        </Form.Item>
                                    </Col >
                                    {doc?.estado==1 ? <Col sm={24} md={2}>
                                        <Form.Item noStyle>
                                            <Button shape="circle" danger type="primary" onClick={() => remove(field.name)} icon={<DeleteOutlined />} style={{marginTop:30}} />
                                        </Form.Item>
                                    </Col> : null}
                                </Row>
                            </div>
                        ))}
                        {doc?.estado==1 ? <Button onClick={() => add()}>Añadir</Button> : null}
                    </div>
                )}
            </Form.List>
        </Form>
    </Modal>
}

export default Gastos