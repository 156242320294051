import { Button, DatePicker, Form, Modal, Space, Table } from "antd"
import { useForm } from "antd/es/form/Form"
import  Dayjs  from "dayjs"
import { useEffect, useState } from "react"
import API from "../../../API"


export const ProduccionReporte = ({ doc, setDoc }) => {
    const [docs,setDocs] = useState()
    const [form] = useForm()
    const cargar=async (payload)=>{
        const {data} = await API.get('api/estanque/'+doc+'/produccion',{params:{inicio:payload.fecha[0].toDate(),fin:payload.fecha[1].toDate()}})
        console.log(data?.docs,"este son los doc")
        setDocs(data?.docs)
    }
    useEffect(()=>{
        form.setFieldValue('fecha',[Dayjs().subtract(1,'month').startOf("month"),Dayjs()])
    },[])
    return <Modal open={doc} title="Registros Financieros" footer={[]} onCancel={() => setDoc(null)} width={960}>
        <br></br>
        <br></br>
        <Form layout="vertical" onFinish={cargar} form={form}>
            <Space size={24}>
                <Form.Item name={'fecha'} label="Rango de fecha">
                    <DatePicker.RangePicker />
                </Form.Item>
                <Button type="primary" htmlType="submit">Generar reporte</Button>
            </Space>
        </Form>
        <br></br>
        <br></br>
        <Table dataSource={docs} pagination={false} scroll={
            { x:980,
             y:500,}
         }>
            <Table.Column title="Fecha de muestreo" dataIndex={'fecha_muestreo'} />
            <Table.Column title="Tiempo cultivo" dataIndex={'tiempo_cultivo'} />
            <Table.Column title="Dias trascurridos" dataIndex={'dias_periodo'} />
            <Table.Column title="Peso Promedio " dataIndex={'peso_promedio_peses'} />
            <Table.Column title="Cantidad de Peces" dataIndex={'cantidad_peses'} />
            <Table.Column title="Incremento biomasa" dataIndex={'incremento_biomasa'} />
            <Table.Column title="Alimento consumido " dataIndex={'alimento_real_consumido'} />
            <Table.Column title="Peso Promedio " dataIndex={'peso_promedio_peses'} />

        </Table>
    </Modal>
}