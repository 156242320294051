import React, { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const [valid, setValid] = useState(null);

  const login = async (data) => {
   
    localStorage.setItem("user",JSON.stringify(data));
    localStorage.setItem("token",data?.token);
    
    setUser(data);
    setToken(data?.token)
    if (data?.rol==4){
      navigate("/select-productor",{ replace: true } );
    }
    navigate("/",{ replace: true });
  };

  const logout = () => {       
         
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("abc");
      localStorage.removeItem("xyz");
      localStorage.removeItem("jul");
      localStorage.removeItem("cbp");
      window.sessionStorage.removeItem("pathname")
      setUser(null);
      setToken(null);
      navigate("/login", { replace: true });
  };

  const verificationSession = () => {
   
    try {
        const _token = localStorage.getItem("token");
        const _user = localStorage.getItem("user");

        if(!_token || !_user){
          return navigate("/login", { replace: true });
        }

        setUser(JSON.parse(_user));
        setToken(_token);
        setValid(true)
    } catch (error) {
        console.error('Error en verificar sesión=>', error);
    }
  };

  useEffect(()=>{
    if(valid){
      let redirect='/'
      let prev_path = window.sessionStorage.getItem("pathname")
      redirect = prev_path || redirect
      navigate(redirect,{replace:true});
    }else if(valid===false){
      navigate("/login", { replace: true });
    }
  },[valid])



  useEffect(() => {
    verificationSession()
  }, [])

  useEffect(()=>{
    if(pathname!='/login'){
      window.sessionStorage.setItem("pathname",pathname)
    }
   
  },[pathname])

  
  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      token,
    }),
    [user,token]
  );

  return (
    <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
  );
};
