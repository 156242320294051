import img1 from "../imagen/inventory.png"

export const BASE_URL=process.env.REACT_APP_BASE_URL
export const TKOL='qXbD4mETF0'
export const PRODUCTOR={value:2,label:'Productor'}
export const ASISTENTE={value:3,label:'Asistente'}
export const ASESOR={value:4,label:'Asesor'}
export const ROL_ADMIN={value:1,label:'Admin'}
export const  colombianPeso_ = Intl.NumberFormat('es-CO');
export const IMG_INVENTARIO = img1

export const ROLES=[PRODUCTOR,ASISTENTE,ASESOR,ROL_ADMIN]
