import { Button, Form, Input, InputNumber, Modal, notification, Select } from "antd"
import { useForm, useWatch } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PouchDB from 'pouchdb-browser'
import API from "../../../API"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import crypto from 'crypto-js';
import { TKOL } from "../../../Constantes"

export const FormularioSubir = ({enviar, setEnviar,onCompletado}) => {
    
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const [granja, setGranja] = useState([])
    const [productores, setProductores] = useState([])
    const {base_de_datos} = useBaseDatos()
    const [formulario,setFormulario] = useState(null)
    const productor_seleccionado = useWatch('productor',form)

    const enviarFormulario = async (payload) => {
        try{
            setCargando(true)
            await base_de_datos.put({ ...formulario, ...payload })
            

            const {data} = await API.post('api/formularios/cargar-formulario-a-productor',payload)
            const {jul,abc} = data

            const productor = productores.find(p=>p._id==productor_seleccionado)
            const bdname=crypto.AES.decrypt(abc,TKOL).toString(crypto.enc.Utf8)
            const bduser=crypto.AES.decrypt(jul,TKOL).toString(crypto.enc.Utf8)
            const bdpassword=crypto.AES.decrypt(productor.xyz,TKOL).toString(crypto.enc.Utf8)
            
            const replicacion = base_de_datos.replicate.to(
                `https://${bduser}:${bdpassword}@admin.agrifishcol.com/${bdname}`,
                {
                    live:false,
                    retry:false,
                    filter:function(doc){
                        return doc._id==formulario._id
                    }
                }
            ).on('active',function(){
                console.log("Active")
            }).on('paused',function(){
                console.log("Paused")
            }).on('denied',function(){
                console.log("Denegado")
                setCargando(false)
            }).on("complete",async function(e){
                console.log("Completado ",e)
                const f = await cargarFormulario()
                await base_de_datos.put({...f,fecha_subido:e.start_time})

                setCargando(false)
                notification.success({
                    message:`Formulario: leidos ${e.docs_read} subidos ${e.docs_written}`
                })
                cargarFormulario()
                setEnviar(null)
                onCompletado()
            }).on("error",function(err){
                setCargando(false)
                notification.error({message:err.toString()})
            })
        }catch(err){
            notification.error({message:err.toString()})
        }
  

    }

    const cargarGranjas = async (id) => {
        try {
            const { data } = await API.get(`api/granja/productor/${id}`)
            console.log(data, "granbjas")
            setGranja(data.docs)
        } catch (error) {

        }

    }

    const cargarProductores = async () => {
        try {
            const { data } = await API.get('api/productor')
            setProductores(data.data)
        } catch (error) {

        }

    }


    const cargarFormulario=async ()=>{
        const data = await base_de_datos?.get(enviar)
        setFormulario(data)
        form.setFieldsValue({productor:data?.productor,granja:data?.granja})
        return data
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        cargarProductores()
    }, [])

    useEffect(()=>{
        if(productor_seleccionado){
            cargarGranjas(productor_seleccionado)
        }
    },[productor_seleccionado])

    useEffect(()=>{
        if(enviar){
           cargarFormulario()
        }
    },[enviar])

    return <Modal open={enviar} title='Subir formulario' closable={!cargando} onCancel={() => setEnviar(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} footer={[]}>
       
        <Form disabled={cargando} style={{marginTop:32}} form={form} layout="vertical" onFinish={enviarFormulario}>
            <Form.Item label="Productor" name='productor' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Select options={productores?.map((est, index) => ({ value: est._id, label: est.nombre }))} filterOption={filterOption} showSearch/>

            </Form.Item>
            <Form.Item label="Granja" name='granja' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Select options={granja?.map((est, index) => ({ value: est._id, label: est.nombre }))} filterOption={filterOption} showSearch />

            </Form.Item>
            <Button type="primary" htmlType="submit" loading={cargando} >Subir</Button>
        </Form>
    </Modal>

}