import { Button, DatePicker, Flex, Form, Modal, Space, Table, notification } from "antd"
import { useForm } from "antd/es/form/Form"
import Dayjs from "dayjs"
import { useEffect, useState } from "react"
import API from "../../../API"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import Dispositivo from "./Dispositivo"
import dayjs from "dayjs"
import { TIPO_PARAMETROS } from "../../../Base/Contantes"




const ParametrosReportes = ({ id_estanque, setEstante }) => {
    const { base_de_datos } = useBaseDatos()
    const [docs, setDocs] = useState()
    const [estanque, setEstanque] = useState(null)
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()

    const cargar = async (payload) => {

        const inicio = payload.fecha[0].startOf("day").toDate()
        const fin = payload.fecha[1].endOf("day").toDate()

        const { docs: data } = await base_de_datos.find({
            selector: {
                estanque: id_estanque,
                type:TIPO_PARAMETROS,
                fecha: {
                    $gte: inicio, $lte: fin
                }
            }
        })
        console.log(data)
        // const { data } = await API.get('api/estanque/' + id_estanque + '/parametros', { params: { inicio: payload.fecha[0].toDate(), fin: payload.fecha[1].toDate() } })

        setDocs(data?.sort(function(a,b){
            return new Date(b.fecha) - new Date(a.fecha);
        }))
    }

    const cargarEstanque = async () => {
        try {
            setCargando(true)
            const d = await base_de_datos.get(id_estanque)
            setEstanque(d)

        } catch (err) {

        } finally {
            setCargando(false)
        }
    }

    const nuevoParametro = () => {
        notification.success({
            message: 'Nueva medición registrada',
            description: ''
        })
        form.submit()
    }



    useEffect(() => {
        if (id_estanque) {
            document.addEventListener(`parametro-${id_estanque}`, nuevoParametro)
            cargarEstanque()
            form.setFieldValue('fecha', [Dayjs().subtract(1, 'month').startOf("month"), Dayjs()])
            form.submit()
        }
        setDocs([])

        return () => {
            document.addEventListener(`parametro-${id_estanque}`, nuevoParametro)
        }
    }, [id_estanque])

    return <Modal open={id_estanque} title={`Parámetros y Mediciones estanque ${estanque?.nombre}`} footer={[]} onCancel={() => setEstante(null)} width={960}>
        <br></br>
        <br></br>
        <Flex justify="space-between" gap="large">
            <Form layout="vertical" onFinish={cargar} form={form}>

                <Form.Item name={'fecha'} label="Rango de fecha">
                    <DatePicker.RangePicker />
                </Form.Item>
                <Button type="primary" htmlType="submit">Generar reporte</Button>

            </Form>
            <Dispositivo id_estanque={id_estanque} />
        </Flex>

        <br></br>
        <br></br>
        <Table loading={cargando} dataSource={docs} pagination={false} scroll={{ x: 400, y: 500 }}>
            <Table.Column title="Fecha de muestra" dataIndex={'fecha'} render={(f) => dayjs(f).format("YYYY/MM/DD hh:mm a")} />
            <Table.Column title="PH" dataIndex={'ph'} />
            <Table.Column title="EC" dataIndex={'ec'} />
            <Table.Column title="OD" dataIndex={'oxigeno'} />
            <Table.Column title="Temp. °" dataIndex={'temperatura'} />
        </Table>
    </Modal>
}

export default ParametrosReportes