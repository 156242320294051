import './App.css';
import { Routes, Route } from "react-router-dom";
import Base from './Base';
import PaginaNoEncontrada from './Componentes/PaginaNoEncontrada';
import Inicio from './Paginas/inicio';
import GranjaListado from './Paginas/Granja/Listado';
import { Estanque } from './Paginas/Estanque/Listado';
import { Producciones } from './Paginas/Producciones/Listar';
import { Insumos } from './Paginas/Insumos/Listar';
import { Ciclos } from './Paginas/Ciclos/Listar';
import { Login } from './Paginas/Login';
import { Parametros } from './Parametro/listar';
import { SelectProductor } from './Paginas/SelectProductor';
import { Reporte } from './Paginas/Reporte';
import { Perfil } from './Paginas/Perfil';
import ListaUsuarios from './Paginas/Usuarios/ListaUsuarios';
import { Graficas } from './Paginas/Graficas';
import * as sw from "./serviceWorker"
import { useEffect } from 'react';
import { ListarFormularios } from './Paginas/Formulario/Listar';
import { GuardarFormulario } from './Paginas/Formulario/Guardar';
import GranjaFormulario from './Paginas/Granja/Formulario';
import ProductorListado from './Paginas/Productores/Listado';
import AsesorListado from './Paginas/Asesores/Listado';

function App() {

  useEffect(()=>{
    sw.register()
  },[])
  
  return (
      <Routes>
        <Route path="/" element={<Base />}>
          <Route index element={<Inicio />} />
          <Route path='/productores' element={<ProductorListado/>} />
          <Route path='/asesores' element={<AsesorListado/>} />

          <Route path='/granjas' element={<GranjaListado/>} />
          <Route path='/Producciones' element={<Producciones/>} />
          <Route path='/parametros' element={<Parametros/>} />
          <Route path='/formulario' element={<GuardarFormulario/>} />
          <Route path='/formulario/:id' element={<GuardarFormulario/>} />
          <Route path='/gestion-usuarios' element={<ListaUsuarios/>}/>
          <Route path='/ciclos-productivos' element={<Ciclos/>} />
          <Route path='/select-productor' element={<SelectProductor/>} />
          <Route path='/reportes' element={<Reporte/>} />
          <Route path='/insumos' element={<Insumos/>} />
          <Route path='/graficas' element={<Graficas/>} />
          <Route path='/perfil' element={<Perfil/>} />
          <Route path='granja/:id/estanques' element={<Estanque/>} />
          <Route path='granja/:id/formulario' element={<GranjaFormulario/>} />
          <Route path='formularios/listar' element={<ListarFormularios/>} />
          <Route path="*" element={<PaginaNoEncontrada />} />
        </Route>
        <Route path='/login' element={<Login/>}/>
      </Routes>
  );
}

export default App;
