import { EditOutlined, LockOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Form, Input, notification, Row, Space, Table } from "antd"
import { useEffect, useState } from "react"
import API from "../../../API"
import ValidarRol from "../../../Componentes/ValidarRol"
import { PRODUCTOR, ROL_ADMIN } from "../../../Constantes"
import ProductorGuardar from "../Guardar"
import { Link } from "react-router-dom"
import ProductorEstado from "../Estado"
import UsuarioClave from "../../../Componentes/Clave"

const ProductorListado=()=>{
    const [docs,setDocs] = useState([])
    const [doc,setDoc] = useState(null)
    const [doc_clave,setDocClave] = useState(null)
    const [cargando, setCargando] = useState(false)
    const [form] = Form.useForm()
    const cargar=async ()=>{
        try{
            const {data} = await API('api/productor',{params:{rol:PRODUCTOR.value}})
           
            setDocs(data?.data)
        }catch(err){

        }finally{

        }
    }

    const onGuardado=()=>{
        setDoc(null)
        cargar()
    }

    const miga=[
        {
            title:<Link to='/'>Inicio</Link>
        },
        {
            title:'Productores'
        }
    ]

    const cargarProductores = async (payload)=>{
        try{
            setCargando(true)
            // console.log(payload.rol, payload.usuario)
            const { data } = await API.get(`api/usuario/listar`, { params: { busqueda: payload.usuario, rol: 2 } })
            
            setDocs(data.data)
        }catch(error){
            notification.error({message:error.toString()})
        }finally{
            setCargando(false)
        }
    }


    return <ValidarRol roles={[ROL_ADMIN.value]} callback={cargar}>
        <ProductorGuardar doc={doc} setDoc={setDoc} onGuardado={onGuardado}/>
        <UsuarioClave doc={doc_clave} setDoc={setDocClave} />
        <Space size={72}>
        <Breadcrumb items={miga}/>
        <Button type="primary" onClick={()=>setDoc({})} icon={<PlusOutlined/>}>Registrar productor</Button>

        </Space>
        <Row>
            <Col span={24}>
                <Form layout="vertical" disabled={cargando} form={form} onFinish={cargarProductores}>
                    <Row gutter={10}>
                        <Col xs={24} md={8}>
                            <Form.Item name="usuario" label="usuario">
                                <Input
                                    placeholder="Busque por nombre"
                                    disabled={cargando}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} md={8}>
                            <Form.Item label="&nbsp;">
                                <Space size={8}>
                                    <Button type="primary" style={{ backgroundColor: "#81BD00" }} loading={cargando} htmlType="submit">Filtrar</Button>
                                    {/* <Button type="text" onClick={limpiar} htmlType="button" >Limpiar Filtro</Button> */}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
        <br></br>
        <br></br>
        <Table dataSource={docs} scroll={
               { x:200,
                y:200,}
            } pagination={false}>
            <Table.Column title="Activo" render={(d)=><ProductorEstado {...d} onEstado={cargar}/>} />
            <Table.Column title="Nombre" dataIndex={'nombre'}/>
            <Table.Column title="Apellido" dataIndex={'apellido'}/>
            <Table.Column title="Correo" dataIndex={'correo'}/>
            <Table.Column title="Acciones" render={(d)=>(<Space size={24}>
                <Button shape="circle" onClick={()=>setDoc(d)} icon={<EditOutlined/>} />
                <Button
                shape="circle"
                onClick={() => setDocClave(d)}
                icon={<LockOutlined />}
              />
            </Space>)} />
        </Table>
    </ValidarRol>
}

export default ProductorListado