
import { Button, Card, Form, Input, notification } from "antd"
import logo from "../../imagen/logo.png"
import useAuth from "../../Contextos/Auth/AuthContext"
import axios from 'axios'
import { BASE_URL, TKOL } from "../../Constantes"
import crypto from 'crypto-js';
import './estilo.css'
import { useEffect, useState } from "react"

export const Login = () => {
    const { login } = useAuth();
    const [ready,setReady] = useState(false)

   
    async function downloadApp() {
        console.log("👍", "butInstall-clicked");
        const promptEvent = window.deferredPrompt;
 
        if (!promptEvent) {
          // The deferred prompt isn't available.
          console.log("oops, no prompt event guardado en window");
          return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
    
        const result = await promptEvent.userChoice;
        console.log("👍", "userChoice", result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
   
        // Hide the install button.
        setReady(false);
    }


    const onFinish = async (values) => {

         let data = {}
        await axios.post(BASE_URL + 'api/usuario/login', {
            correo: values.username,
            password: values.password
        }).then(function (response) {

            data = {
                username: response.data.correo,
                _id: response.data._id,
                token: response.data.token,
                first_name: response.data.nombre,
                last_name: response.data.apellido,
                rol: response.data.rol,
                productor: response.data.productor
            }
            localStorage.setItem('xyz',response.data.xyz)

            if(response.data?.abc){
                localStorage.setItem('abc', response.data?.abc)
                localStorage.setItem('jul', response.data?.jul)
            }
            login(data)
        }).catch(function (error) {
            console.log(error)
            notification.error({ message: error.response.data.message })
        })
       

    };

    useEffect(() => {

        window.addEventListener("beforeinstallprompt", (event) => {
          // Prevent the mini-infobar from appearing on mobile.
          event.preventDefault();
          console.log("👍", "beforeinstallprompt", event);
          // Stash the event so it can be triggered later.
          window.deferredPrompt = event;
          // Remove the 'hidden' class from the install button container.
          setReady(true);
        });
    }, []);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <div className="page-login">

        
            <Card className="card-login" 
                cover={
                    <div className="login-cover">
                        <img style={{width:'100%'}}  src="https://images.unsplash.com/photo-1485452499676-62ab02c20e83?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
                    </div>
                }
            >
            <Form
            layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
             <img src={logo} style={{ width: 250,position:'absolute',top:0,right:0 }} alt="" />
          
                <Form.Item
                    label="Nombre de usuario"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

             
                    <Button type="primary" block htmlType="submit">
                        Acceder
                    </Button>
            </Form>
            </Card>
            <br></br>
           <center>
           <Button type="primary" onClick = {()=>downloadApp()}>Instalar aplicativo</Button>
           </center>
      
    </div>
}