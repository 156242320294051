import { Form, Input, InputNumber, Modal, notification } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TIPO_ESTANQUE } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"


export  const EstanqueGuardar=({estanque,setEstanque,alGuardar})=>{
    const {base_de_datos}=useBaseDatos()
    const [cargando,setCargando]=useState(false)
    const [form] = useForm()
    const { id } = useParams()
    const user=useAuth()
    const guardar = async (payload)=>{
        try{
            setCargando(true)
            let info = null
            if(estanque._id){
                info  = await base_de_datos.put({...estanque,...payload})
            }else{  
                const fecha = new Date();
                const añoActual = fecha.getFullYear();
                
                const productor=user.user.rol==PRODUCTOR.value?user.user._id:user.user.productor
                           
                info = await base_de_datos.post({...payload,type:TIPO_ESTANQUE,id_granja:id,anio_creacion:añoActual,usuario:user.user._id,productor:productor})
            }
           
            notification.success({message:'Estanque guardada'})
            setEstanque(null)
            alGuardar()
            form.setFieldsValue({
                granja:30
            })
        }catch(error){
            notification.error({message:error.toString()})
        }finally{
            setCargando(false)
        }
      
    }
    useEffect(()=>{
    if(estanque){
        form.setFieldsValue(estanque)
    }else{
        form.resetFields()
    }
    },[estanque])
    return <Modal open={estanque} title='Guardar Estanque' closable={!cargando} onCancel={()=>setEstanque(null)} okText='Guardar' cancelButtonProps={{style:{display:'none'}}} onOk={()=>form.submit()}>
            <Form form={form} layout="vertical" onFinish={guardar}>
                <Form.Item label="Nombre" name='nombre' rules={[{required:true,message:'Campo requerido'}]} >
                    <Input/>
                </Form.Item>
                <Form.Item label="Volumen M3" name='volumen' rules={[{required:true,message:'Campo requerido'}]} >
                    <InputNumber style={{width:'100%'}}/>
                </Form.Item>
                <Form.Item label="Capacidad Peces" name='capacidad_peses' rules={[{required:true,message:'Campo requerido'}]} >
                    <InputNumber style={{width:'100%'}}/>
                </Form.Item>               
                
            </Form>
    </Modal>

}