import { Form, Input, Modal, notification, Select, Row, Col, DatePicker, InputNumber } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { MESES, TIPO_ESTANQUE, TIPO_GRANJA, TIPO_PRODUCCION } from "../../../Base/Contantes"
import { PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import dayjs from 'dayjs'

export const ProduccionGuardar = ({ produccion, setProduccion, alGuardar }) => {
    const { base_de_datos } = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const user = useAuth()
    const [form] = useForm()
    const { id } = useParams()
    const [granjas, setGranjas] = useState([])
    const [estanque, setEstanque] = useState([])
    const [primer_registro,setPrimerRegistro] = useState(null)
    const [registro_anterior,setRegistroAnteriror] = useState(null)

    const granjas_ = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_GRANJA } })
        setGranjas(data?.docs)
    }

    const estanques_ = async (id) => {
       
        const data = await base_de_datos.find({ selector: { type: TIPO_ESTANQUE, id_granja: id } })
        setEstanque(data?.docs)
    }

    const guardar = async (payload) => {
        try {
            setCargando(true)
            let info = null
            
            payload.fecha_muestreo = payload.fecha_muestreo.toDate()
            
            if (produccion._id) {
                info = await base_de_datos.put({ ...produccion, ...payload })
                notification.success({ message: 'Producción actualizada' })
                return
            } else {
                const productor = user.user.rol == PRODUCTOR.value ? user.user._id : user.user.productor
                info = await base_de_datos.post({ ...payload, type: TIPO_PRODUCCION, usuario: user.user._id,productor })
            }
            
            notification.success({ message: 'Producción guardada' })
            setProduccion(null)
            alGuardar()

        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }

    const consultarRegistroAnterior=async ()=>{
        const {docs:[_primer_registro]} = await base_de_datos?.find({
            selector:{
                type:TIPO_PRODUCCION,
                estanque:estanque_seleccionado
            },
            limit:1
        })
        setPrimerRegistro(_primer_registro)

        const {docs:[_registro_anterior]} = await base_de_datos?.find({
            selector:{
                type:TIPO_PRODUCCION,
                fecha_muestreo:{$lt:fecha_muestreo.toDate()},
                estanque:estanque_seleccionado
            },
            sort:[{'_id':'desc'}],
            limit:1
        })
        setRegistroAnteriror(_registro_anterior)

     
       
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    //const peso_total_peces_capturados = Form.useWatch('peso_total_peces_capturados', form)
    const fecha_muestreo=Form.useWatch('fecha_muestreo',form)
    const estanque_seleccionado=Form.useWatch('estanque',form)

    const peso_promedio=Form.useWatch('peso_promedio',form) || 0
   
    const cantidad_peses_muertos = Form.useWatch('cantidad_peses_muertos',form)
    const cantidad_peses = Form.useWatch('cantidad_peses', form)
    const biomasa_final = Form.useWatch('biomasa_final',form)
    const biomasa_inicial = Form.useWatch('biomasa_inicial',form)
    const tasa_alimentacion = Form.useWatch('tasa_alimentacion',form)
    const numero_dias = Form.useWatch('dias_periodo',form)
    const alimento_real_consumido = Form.useWatch('alimento_real_consumido',form)
    //const peso_promedio = peso_total_peces_capturados / cantidad_peses;
    const biomasa_promedio = (cantidad_peses * peso_promedio) / 1000
    const incremento_biomasa = biomasa_final-biomasa_inicial
    //const racion_diaria = (cantidad_peses*peso_promedio*tasa_alimentacion)/1000
   // const cantidad_alimento_periodo = (racion_diaria*numero_dias)
    const factor_conversion = (alimento_real_consumido / incremento_biomasa)
  


    useEffect(() => {
        if (produccion?._id) {
            form.setFieldsValue({
                ...produccion,
                fecha_muestreo:dayjs(produccion?.fecha_muestreo)
            })
            estanques_()
        } else {
            form.resetFields()
        }
    }, [produccion])

    useEffect(() => {
        granjas_()
    }, [])



    useEffect(()=>{
        if(!primer_registro){
            form.setFieldValue('biomasa_inicial',0)
            form.setFieldValue('cantidad_alimento_periodo',0)
            form.setFieldValue('cantidad_peses_muertos',0)
        }else if(fecha_muestreo){
            form.setFieldValue('cantidad_peses',primer_registro.cantidad_peses)
            form.setFieldValue('tiempo_cultivo',fecha_muestreo.diff(dayjs(primer_registro.fecha_muestreo),'days'))
        }
    },[primer_registro])

    useEffect(()=>{
        if(registro_anterior && fecha_muestreo){
            const dias_periodo = fecha_muestreo.diff(dayjs(registro_anterior.fecha_muestreo),'days')
            const racion_diaria = registro_anterior.racion_diaria
            const cantidad_alimento_periodo=racion_diaria * dias_periodo

            form.setFieldValue('dias_periodo',dias_periodo)
            form.setFieldValue('racion_diaria',racion_diaria)
            form.setFieldValue('biomasa_inicial',registro_anterior.biomasa_promedio)
            form.setFieldValue('cantidad_peses',registro_anterior.cantidad_peses-cantidad_peses_muertos)
            form.setFieldValue('cantidad_alimento_periodo',cantidad_alimento_periodo)
        }
    },[registro_anterior,cantidad_peses_muertos])

    useEffect(()=>{
        if(fecha_muestreo && estanque_seleccionado){
            consultarRegistroAnterior()
        }else {
            setPrimerRegistro(null)
            setRegistroAnteriror(null)
            form.setFieldValue('cantidad_peses_muertos',0)
        }
    },[estanque_seleccionado,fecha_muestreo])

    useEffect(()=>{
        form.setFieldValue('factor_conversion',factor_conversion)
    },[factor_conversion])
    // useEffect(() => {
    //     if (peso_promedio) {
    //         form.setFieldValue('peso_promedio_peses', peso_promedio)
    //     } else {
    //         form.setFieldValue('peso_promedio_peses', 0)
    //     }
    // }, [peso_promedio])

    useEffect(() => {
        if (biomasa_promedio) {
            form.setFieldValue('biomasa_promedio', biomasa_promedio)
        } else {
            form.setFieldValue('biomasa_promedio', 0)
        }
    }, [biomasa_promedio])

    useEffect(()=>{
        if(incremento_biomasa){
            form.setFieldValue('incremento_biomasa', incremento_biomasa)
        }else{
            form.setFieldValue('incremento_biomasa', 0)
        }
    },[incremento_biomasa])

    

  

    useEffect(()=>{
       
        if(factor_conversion){
            form.setFieldValue('factor_conversion', factor_conversion)
        }else{
            form.setFieldValue('factor_conversion', 0) 
        }
    },[factor_conversion])

    return <Modal open={produccion} title='Guardar registro financiero' closable={!cargando} onCancel={() => setProduccion(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} width={800} onOk={() => form.submit()}>
        <br></br>
        <br></br>
        <Form form={form} layout="vertical" onFinish={guardar}>
            <Row gutter={30}>
                <Col sm={24} md={8}>
                    <Form.Item label="Fecha muestreo" name='fecha_muestreo' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <DatePicker style={{ width: '100%' }} />
                        {/* <input type='date'/> */}
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item label="Granja" name='granja' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select options={granjas?.map((granja, index) => ({ value: granja._id, label: granja.nombre }))} onChange={estanques_} filterOption={filterOption} showSearch />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item label="Estanque" name='estanque' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <Select options={estanque?.map((est, index) => ({ value: est._id, label: est.nombre }))} filterOption={filterOption} showSearch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col sm={24} md={12}>
                    <Form.Item label="Tiempo de cultivo (Días)" name='tiempo_cultivo' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                    <Form.Item label="No. Dias del periodo" name='dias_periodo' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col sm={24} md={12}>
                    <Form.Item label="No. de peces muertos" name='cantidad_peses_muertos' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{ width: '100%' }} readOnly={!primer_registro} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                    <Form.Item label="No. de peces" name='cantidad_peses' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{ width: '100%' }} readOnly={primer_registro}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col sm={24} md={12}>
                    <Form.Item label="Peso Promedio(Kg)" name='peso_promedio_peses' >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
            <Col sm={24} md={6}>
                    <Form.Item label="Biomasa Promedio" name='biomasa_promedio' >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
               
                <Col sm={24} md={6}>
                    <Form.Item label="Biomasa inicial" name='biomasa_inicial' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
                <Col sm={24} md={6}>
                    <Form.Item label="Biomasa final" name='biomasa_final' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
                <Col sm={24} md={6}>
                    <Form.Item label="Incremento Biomasa" name='incremento_biomasa' >
                        <InputNumber style={{ width: '100%' }}  />
                    </Form.Item>
                </Col>
            </Row>
          
            <Row gutter={30}>
               
                <Col sm={24} md={8}>
                    <Form.Item label="Ración diaria (Kg)" name='racion_diaria'>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item label="Cantidad alimento periodo" name='cantidad_alimento_periodo' >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item label="Tasa de alimentación" name='tasa_alimentacion' rules={[{ required: true, message: 'Campo requerido' }]} >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col sm={24} md={12}>
                    <Form.Item label="Cantidad alimento real consumido" name='alimento_real_consumido' rules={[{ required: true, message: 'Campo requerido' }]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                    <Form.Item label="Factor de Conversion" name='factor_conversion' >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            



        </Form>
    </Modal>
}