import { Button, Form, Modal ,Input, notification} from "antd"
import { useForm } from "antd/es/form/Form"
import { useState } from "react"
import API from "../../API"

const UsuarioClave=({doc,setDoc})=>{
    const [form] = useForm()
    const [cargando,setCargando] = useState(false)
    console.log(doc)
    const guardar=async ({password})=>{
        try {
            setCargando(true)
            await API.patch(`api/usuario/${doc._id}/actualizar/perfil`,{password})
            setDoc(null)
            notification.success({message:'Contraseña actualizada'})
        }catch(err){
            notification.error({message:'Hubo un error',description:err.toString()})
        }finally{
            setCargando(false)
        }
    }

    return <Modal maskClosable={false} closable={!cargando} open={doc} onCancel={()=>setDoc(null)} title="Actualizar contraseña" footer={[<Button loading={cargando} onClick={()=>form.submit()} type="primary">Actualizar</Button>]}>
        <Form disabled={cargando} layout="vertical" form={form} onFinish={guardar}>
            <Form.Item label="Nueva Contraseña" name={'password'} rules={[{required:true,message:'Campo requerido'}]}>
                <Input.Password/>
            </Form.Item>
        </Form>
    </Modal>
}

export default UsuarioClave