import { DeleteOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, message, Popconfirm, Row, Space, Tooltip, Typography } from "antd"
import { Link } from "react-router-dom"
import '../../Css/card.css';
import useBaseDatos from "../../Ganchos/BaseDatos"
import useAuth from "../../Contextos/Auth/AuthContext"
import { ASESOR, ASISTENTE, IMG_INVENTARIO, PRODUCTOR, ROL_ADMIN } from "../../Constantes";
import { FloatButton } from 'antd';
import insumos from '../../assets/images/insumos.png'
import siclos_ from '../../assets/images/Ciclos_.png'
import estanque_ from '../../assets/images/estanques_.png'
import ValidarRol from "../../Componentes/ValidarRol";
import parametros from"../../static/parametros.png"
import asistente from"../../static/asistente.png"
import granero from"../../static/granero.png"
import granjero from"../../static/granjero.png"
import asesoramiento from"../../static/asesoramiento.png"
import formulario from"../../static/documento.png"


const Inicio = () => {
    const { logout } = useAuth();
    const { base_de_datos } = useBaseDatos()
    const { user } = useAuth()

    const remove_base_datos = () => {
        base_de_datos.destroy()
    }
    const cerrar_session = () => {
        remove_base_datos()
        logout()
    }
    const confirm = (e) => {
        remove_base_datos()
        message.success('Borrada con Éxito ');
    };
    const cancel = (e) => {
       
        message.error('Click on No');
    };
    return <div>
        
        <ValidarRol roles={[ROL_ADMIN.value]}>
            <Row gutter={[16, 16]}>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/productores'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={granjero} style={{ width: 42, borderRadius: 10 }} />
                                    <br></br>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Productores</Typography.Title>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                </Col>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/asesores'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={asesoramiento} style={{ width: 42, borderRadius: 10 }} />
                                    <br></br>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Asesores</Typography.Title>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                </Col>
                
            </Row>
        </ValidarRol>

        <ValidarRol roles={[ASESOR.value]}>
            <Row gutter={[16, 16]}>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/formularios/listar'>
                        <Card className="custom-card"
                            hoverable
                            style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img src={formulario} style={{ width: 42, borderRadius: 10 }} />
                                <br></br>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <Typography.Title level={5} style={{ margin: 0 }}>Formularios de caracterización</Typography.Title>
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </ValidarRol>

        <ValidarRol roles={[PRODUCTOR.value, ASISTENTE.value]}>
        <Typography.Title level={4} style={{ marginBottom: 32 }}>Registros Financieros</Typography.Title>
            <Row gutter={[16, 16]}>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/ciclos-productivos'>
                        <Card className="custom-card"
                            hoverable
                            style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img src={siclos_} style={{ width: 42, borderRadius: 10 }} />
                                <br></br>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <Typography.Title level={5} style={{ margin: 0 }}>Ciclos</Typography.Title>
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/producciones'>
                        <Card className="custom-card"
                            hoverable
                            style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img src={estanque_} style={{ width: 42, borderRadius: 10 }} />
                                <Typography.Title level={5} style={{ margin: 0 }}>Registro de indicadores</Typography.Title>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col xs={12} md={8} lg={6}>
                    <Link to='/parametros'>
                        <Card className="custom-card"
                            hoverable
                            style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img src={parametros} style={{ width: 42, borderRadius: 10 }} />
                                <Typography.Title level={5} style={{ margin: 0 }}>Parámetros y mediciones</Typography.Title>
                            </div>
                        </Card>
                    </Link>
                </Col>

            </Row>
        </ValidarRol>

        <ValidarRol roles={[PRODUCTOR.value]}>
            <>
                <Typography.Title level={4} style={{ marginBottom: 32 }}>Reportes</Typography.Title>
                <Row gutter={[16, 16]}>
                    <Col xs={12} md={8} lg={6}>
                        <Link to='/graficas'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src="https://cdn-icons-png.flaticon.com/512/7169/7169113.png" style={{ width: 32, borderRadius: 10 }} />
                                    <Typography.Title level={5} style={{ margin: 0 }}>Parámetros y Mediciones</Typography.Title>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                        <Link to='/reportes'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src="https://cdn-icons-png.flaticon.com/512/6713/6713123.png " style={{ width: 32, borderRadius: 10 }} />
                                    <Typography.Title level={5} style={{ margin: 0 }}>Registros Financieros</Typography.Title>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </>
        </ValidarRol>

        <ValidarRol roles={[PRODUCTOR.value]}>
            <>
                <Typography.Title level={4} style={{ marginBottom: 32 }}>Configuraciones</Typography.Title>
                <Row gutter={[16, 16]}>
                    <Col xs={12} md={8} lg={6}>
                        <Link to='/granjas'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                {/* <Space size={32}> */}
                                <div style={{ textAlign: 'center' }}>
                                    <img src={granero} style={{ width: 32, borderRadius: 10 }} />
                                    <Typography.Title level={5} style={{ margin: 0 }}>Granjas </Typography.Title>
                                </div>
                                {/* </Space> */}
                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                        <Link to='/insumos'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={IMG_INVENTARIO} style={{ width: 32 }} />
                                    <Typography.Title level={5} style={{ margin: 0 }}>Insumos</Typography.Title>
                                </div>

                            </Card>
                        </Link>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                        <Link to='/gestion-usuarios'>
                            <Card className="custom-card"
                                hoverable
                                style={{ boxShadow: "0px 3px 6px #00000029", borderRadius: 8, opacity: 1 }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={asistente} style={{ width: 32, borderRadius: 10 }} />
                                    <Typography.Title level={5} style={{ margin: 0 }}>Asistentes</Typography.Title>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </>
        </ValidarRol>

        {/* <Tooltip placement="bottomRight" title={'Borrar base de datos'}>
            <Popconfirm
                title="Eliminar base de Datos"
                description="Estas seguro que deseas borrarla?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Si"
                cancelText="No"
            >
                <FloatButton
                    // onClick={() => remove_base_datos}
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                />
            </Popconfirm>
        </Tooltip> */}
    </div>
}

export default Inicio