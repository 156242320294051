import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { TIPO_ESTANQUE } from "../../../Base/Contantes"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import CicloReporte from "../CicloReporte"
import { EstanqueGuardar } from "../Guardar"
import ParametrosReportes from "../ParametroReporte"
import ParametrosReporte from "../ParametroReporte"
import { ProduccionReporte } from "../produccionReporte"


export const Estanque = () => {
    const { base_de_datos } = useBaseDatos()
    const [estanques, setEstanques] = useState([])
    const [estanque, setEstanque] = useState(null)
    const [doc, setDoc] = useState(null)
    const [doc2, setDoc2] = useState(null)
    const [doc3, setDoc3] = useState(null)
    const { id } = useParams()
    const cargar = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE, id_granja: id } })
        console.log(data, "esta es la data")
        setEstanques(data?.docs)
    }
    const borrar = async (doc) => {
      
        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    useEffect(() => {
        cargar()
    }, [base_de_datos])

    return <div>
        <Space size={32}>
            <Breadcrumb items={[{ title: <Link to='/granjas/' >Granja</Link> }, { title: 'Estanques' }]} />
            <Button icon={<PlusOutlined/>}  type="link" onClick={() => setEstanque({})}>Añadir estanque</Button>
        </Space>
        <br></br>
        <br></br>
        <EstanqueGuardar estanque={estanque} setEstanque={setEstanque} alGuardar={cargar} />

        <ParametrosReportes id_estanque={doc} setEstante={setDoc} />
        <CicloReporte doc={doc2} setDoc={setDoc2} />
        <ProduccionReporte doc={doc3} setDoc={setDoc3} />
        <Table 
            dataSource={estanques} 
            rowKey='_id' 
            pagination={false}
            scroll={
                { 
                    x:600,
                    y:400
                }
            }
        >
            <Table.Column title='Nombre' dataIndex={'nombre'} />
            <Table.Column title='Volumen' dataIndex={'volumen'} />
            <Table.Column title='Capacidad peces ' dataIndex={'capacidad_peses'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Tooltip title={'Editar'}>
                    <Button onClick={() => setEstanque(doc)} icon={<EditOutlined  />} shape='circle'></Button>
                </Tooltip>
                <Tooltip title={'Eliminar'}>
                <Popconfirm title="Se borrará el estanque" onConfirm={() => borrar(doc)}>
                    <Button shape="circle" danger icon={<DeleteOutlined />}></Button>
                </Popconfirm>
                </Tooltip>
                <Tooltip title="Parámetros y mediciones">
                    <Button shape="circle" onClick={() => setDoc(doc._id)} icon={<img src='https://cdn-icons-png.flaticon.com/512/4289/4289939.png' width={32} />} />
                </Tooltip>
                <Tooltip title="Ciclos y Gastos">
                    <Button shape="circle" onClick={() => setDoc2(doc._id)} icon={<img src="https://cdn-icons-png.flaticon.com/512/6786/6786333.png" width={32} />} />
                </Tooltip>
                <Tooltip title="Registros financieros">
                    <Button shape="circle" onClick={() => setDoc3(doc._id)} icon={<img src="https://cdn-icons-png.flaticon.com/512/9874/9874715.png" width={32} />} />
                </Tooltip>
            </Space>} />
        </Table>
    </div>
}