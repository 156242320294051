import { CheckOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, MoneyCollectOutlined, PlusOutlined, PoweroffOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, DatePicker, Form, Popconfirm, Row, Select, Space, Table, Divider, Tooltip, Popover, Badge, Spin, Typography, Tag } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { TIPO_CICLOS, TIPO_ESTANQUE, TIPO_GRANJA, TIPO_INSUMOS } from "../../../Base/Contantes"
import { ASISTENTE, PRODUCTOR } from "../../../Constantes"
import useAuth from "../../../Contextos/Auth/AuthContext"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import { CiclosGuardar } from "../Guardar"
import Gastos from "../Guardar/Gastos"
import Papa from 'papaparse'
import siclos_imagen from '../../../static/Ciclos_.png'
import CicloCerrar from "../Cerrar"

export const Ciclos = () => {
    const [ciclos, setciclos] = useState([])
    const { base_de_datos } = useBaseDatos()
    const [selectCiclo, setSelecCiclo] = useState([])
    const [ciclo, setCiclo] = useState(null)
    const [filtrado, setFiltrado] = useState(false)
    const [gasto, setGasto] = useState(null)
    const [cerrar, setCerrar] = useState(null)
    const [granjas, setGranjas] = useState([])
    const [estanque, setEstanque] = useState([])
    const [form] = Form.useForm()
    const user = useAuth()
    const [insumos_, setinsumos] = useState([])
    const [estanqueglobales, setEstanqueGlobales] = useState([])

    const granja = Form.useWatch('granja', form)

    // const cargar = async () => {
    //     if (user.user.rol === ASISTENTE.value) {
    //         const data = await base_de_datos?.find({ selector: { type: TIPO_CICLOS, usuario: user.user._id } })
    //         console.log(data, "esta es la data")
    //         setciclos(data?.docs)
    //         setSelecCiclo(data?.docs.map(p => ({ value: p?._id, label: p?.fecha_inicio})))
    //     } if (user.user.rol === PRODUCTOR.value) {
    //         const data = await base_de_datos?.find({ selector: { type: TIPO_CICLOS, productor: user.user._id } })
    //         console.log(data, "esta es la data")
    //         setciclos(data?.docs)
    //         setSelecCiclo(data?.docs.map(p => ({ value: p?._id, label:`fecha: ${p?.fecha_inicio} nombre: ${p?.nombre_ciclo}`})))
    //     }
    // }

    const cargar = async (payload) => {

        const filtros = { selector: { type: TIPO_CICLOS, ...payload } }

        if (user.user.rol === ASISTENTE.value) {
            filtros.selector.usuario = user.user._id
        }


        const data = await base_de_datos?.find(filtros)
        console.log("docs ",data.docs)
        setciclos(data.docs)
    }

    const borrar = async (doc) => {

        const _doc = await base_de_datos.get(doc._id);
        const response = await base_de_datos.remove(_doc);
        await base_de_datos.compact()
        cargar()
    }

    const filtrado_parametros = (payload) => {
        const { fecha, ...resto } = payload
        const filtros = {
            fecha_inicio: { $gte: fecha[0].toDate(), $lte: fecha[1].toDate() },
            ...resto
        }
        cargar(filtros)

    }

    const Estanque_globales = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE } })

        setEstanqueGlobales(data?.docs)
    }

    const insumoData = async () => {
        const data = await base_de_datos.find({ selector: { type: TIPO_INSUMOS } })
     
        setinsumos(data?.docs)
    }

    const cargar_granjas = async () => {

        const data = await base_de_datos?.find({ selector: { type: TIPO_GRANJA } })

        setGranjas(data?.docs)

    }

    const cargarEstanque = async () => {
        const data = await base_de_datos?.find({ selector: { type: TIPO_ESTANQUE, id_granja: granja } })

        setEstanque(data?.docs)
    }

    //-------------csv--------
    const DescargarCSV =  () => {

        let insumo=[]
        const dataCSV =  ciclos?.map(e =>  {        
                // granja:granjas.find(g=>g._id==e.granja).nombre,
                // estanque:estanqueglobales.find(g=>g._id==e.estanque).nombre,                
                // anio_creacion: e?.anio_creacion,
                // fecha: e?.fecha,
                // cantidad_peses: e?.cantidad_peses,
                // margen_utilidad_ponderado: e?.margen_utilidad_ponderado,
                // nombre_ciclo: e?.nombre_ciclo,
                // peso_de_venta: e?.peso_de_venta,         
                
                return e?.insumos.map(i=>({
                    ciclo:e.nombre_ciclo,
                    insumo:insumos_.find(g=>g._id==i.insumo).nombre_insumo,
                    total:i.total,
                    fecha: e?.fecha,
                    margen_utilidad_ponderado: e?.margen_utilidad_ponderado,
                    cantidad_peses: e?.cantidad_peses,
                    estanque:estanqueglobales.find(g=>g._id==e.estanque).nombre, 
                    fecha:i.fecha_insumo,
                    granja:granjas.find(g=>g._id==e.granja).nombre
                }))              
            
            }).reduce((a,b)=>[...a,...b],[])

        const csv = Papa.unparse(dataCSV)
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Ciclos.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //-------------------------

    const onCancelGasto = () => {
        cargar()
        setGasto(null)
    }

    // useEffect(() => {
    //     if (ciclos.length > 0) {
    //         setSelecCiclo([...ciclos.map(p => ({ value: p?._id, label: p?.fecha_inicio}))])
    //     }
    //     cargar()

    // }, [base_de_datos])

    useEffect(() => {
        form.setFieldValue('fecha', [dayjs().subtract(1, 'year'), dayjs()])
        cargarEstanque()
        cargar_granjas()
        form.submit()
        Estanque_globales()
        insumoData()
    }, [])

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const onSearch = (value) => {
        console.log('search:', value);
    };

    return <div>
        <Space size={72}>
            <Breadcrumb items={[{ title: <Link to='/' >inicio</Link> }, { title: 'Ciclos' }]} />
            <Button type="link" icon={<PlusOutlined />} onClick={() => setCiclo({})}>Registrar nuevo ciclo productivo</Button>
            <Button type="primary" htmlType="submit" onClick={() => DescargarCSV()} style={{ backgroundColor: "#81BD00" }}>Descargar CSV</Button>

        </Space>
        <br></br>
        <br></br>
        <div style={{ position: 'fixed', bottom: -16, right: -8, transform: 'rotate(344deg)' }}>
            <Popover content="Registra tus siclos productivos" title="Módulo ciclos" trigger='click'>
                <img width={128} src={siclos_imagen} />
            </Popover>
        </div>
        <CicloCerrar ciclo={cerrar} setCiclo={setCerrar} onCerrado={()=>form.submit()} />
        <CiclosGuardar ciclo={ciclo} setCiclo={setCiclo} alGuardar={cargar} />
        <Gastos doc={gasto} onCancelGasto={onCancelGasto} ciclo={ciclos} />
        <Divider />
        <Form layout="vertical" form={form} onFinish={filtrado_parametros}>
            <Row gutter={10}>
                <Col sm={24} md={8}>
                    <Form.Item name="fecha" label="Rango de fecha" rules={[{ required: true, message: 'ingrese el  Usuario', },]}>
                        <DatePicker.RangePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item name="granja" label="Granja">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Seleccione Granja"
                            optionFilterProp="children"
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={granjas.map(e => ({ value: e?._id, label: e?.nombre }))}
                        // disabled={filtrado}
                        />
                    </Form.Item>
                </Col>
                <Col sm={24} md={8}>
                    <Form.Item name="estanque" label="Estanque">
                        <Select
                            showSearch
                            allowClear
                            placeholder="Seleccione Parametro"
                            optionFilterProp="children"
                            // onChange={onChange}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={estanque?.map(e => ({ value: e?._id, label: e?.nombre }))}
                        // disabled={filtrado}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: "#81BD00" }}>Consultar</Button>
        </Form>
        <Divider />
        <Table
            dataSource={ciclos}
            rowKey='_id'
            pagination={false}
            scroll={
                {
                    x: 600,
                    y: 400
                }
            }
        >
            <Table.Column title="Estado"  render={(doc)=>{
                if(doc.estado==1){
                    return <>
                    <Tag color="blue"> Abierto</Tag>
                    <Button icon={<PoweroffOutlined />} shape='circle' onClick={() => setCerrar(doc)}></Button>
                    </>
                }
                return <Tag color="green">Cerrado</Tag>
            }} />
            <Table.Column title='Nombre' dataIndex={'nombre_ciclo'} />
            <Table.Column title='Fecha Inicio' dataIndex={'fecha_inicio'} render={(f) => dayjs(f).format("YYYY/MM/DD")} />
            <Table.Column title='Fecha Final' dataIndex={'fecha_final'} render={(f) => {
                if (f) {
                    return dayjs(f).format("YYYY/MM/DD")
                }
                return 'Indefinido'
            }} />
            <Table.Column title='Cantidad de Peces recolectados' dataIndex={'cantidad_peses'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>

                <Tooltip title='Añadir gastos'>
                    <Button type="primary" shape="circle" onClick={() => setGasto(doc)} icon={<MoneyCollectOutlined />} ></Button>
                </Tooltip>
                {doc.estado==1 ? <>
                    <Tooltip  title='Editar'>
                    <Button icon={<EditOutlined />} shape='circle' onClick={() => setCiclo(doc)}></Button>
                </Tooltip>
                </> : null}
                <Tooltip title='Eliminar'>
                    <Popconfirm title="Se borrar la granja y sus estantes" onConfirm={() => borrar(doc)}>
                        <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                    </Popconfirm>
                </Tooltip>
            </Space>} />
        </Table>
    </div>
}