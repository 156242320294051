import { Button, Dropdown, Layout, Space, Typography } from "antd"
import { Outlet, useNavigate } from "react-router-dom"
import { CloudUploadOutlined, UserOutlined, WifiOutlined } from "@ant-design/icons"
import logo from '../assets/images/logo.png'
import useAuth from "../Contextos/Auth/AuthContext"
import useBaseDatos from "../Ganchos/BaseDatos"
import { ROLES } from "../Constantes"
import useConexion from "../Hook/Conexion"

const { Header, Content } = Layout

const Base = () => {
  const  navigate  = useNavigate()
  const { logout } = useAuth();
  const user=useAuth()
  const online = useConexion()
  const { base_de_datos ,nube} = useBaseDatos()
  
  const remove_base_datos = () => {
    base_de_datos.destroy()
  }

  const cerrar_session = () => {
    remove_base_datos()
    logout()
  }

  const menus = [
    {
      key: '2',
      label: (
        <Typography.Text onClick={()=>navigate('/perfil')}>Perfil</Typography.Text>
        )
      },
      {
        key: '1',
        label: (
          <Typography.Text onClick={cerrar_session}>Salir</Typography.Text>
        )
      }
    ]
    const rol = ROLES.find(r=>r.value==user.user.rol)?.label

  return <Layout>
    <Header style={{
      backgroundColor: '#0059BE'
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <div style={{overflow:'hidden'}}> */}
        <img src={logo} style={{ width: 50, height: 50 }} />
        {/* </div> */}
        <div>

          <Space size={32}>
            <CloudUploadOutlined style={{color:nube && online ? 'whitesmoke':'red',fontSize:14}}/>
            <WifiOutlined style={{color:online ? 'whitesmoke':'red',fontSize:14}}/>
          </Space>

          {user.user && <Dropdown
            menu={{ items: menus }}
          >
            <Button icon={<UserOutlined/>} type="text" style={{color:'whitesmoke'}}>{rol}-{user.user.first_name} {user.user.last_name}</Button>
          </Dropdown>}
        </div>
      </div>
    </Header>
    <Content
      style={{
        padding: '50px', minHeight: '90%'
      }}
    >
      <Outlet />
    </Content>
  </Layout>
}

export default Base