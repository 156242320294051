import { Button, DatePicker, Divider, Form, InputNumber, Modal, Typography, notification } from "antd"
import { useEffect, useState } from "react"
import useBaseDatos from "../../../Ganchos/BaseDatos"
import { useForm } from "antd/es/form/Form"

const CicloCerrar = ({ ciclo, setCiclo,onCerrado }) => {
    const {base_de_datos} = useBaseDatos()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const guardar = async (payload) => {
        try {
            setCargando(true)
            const _ciclo = await base_de_datos.get(ciclo._id)
            const {fecha_final,...resto} = payload

            await base_de_datos.put({ ..._ciclo, ...resto,estado:0, fecha_final:fecha_final.toDate() })
            setCiclo(null)
            onCerrado()
            notification.success({message:'Ciclo cerrado. ',description:'Dirigete a reportes -> Registros financieros, para ver los datos calculados.'})
        } catch (error) {
            notification.error({
                message:'Error',
                description:error.toString()
            })
        } finally {
            setCargando(false)
        }
    }
    

    const cargar = async () => {
        if (base_de_datos && ciclo?._id) {
            const _ciclo = await base_de_datos.get(ciclo._id)
            console.log(_ciclo)
        }
    }


    useEffect(() => {
        cargar()
    }, [base_de_datos, ciclo])

    return <Modal open={ciclo} closable={!cargando} onCancel={() => setCiclo(null)} title="Cerrar ciclo productivo" footer={[<Button type="primary" onClick={()=>form.submit()}>Cerrar el ciclo</Button>]} maskClosable={false}>
        <Typography.Paragraph>Haz llegado al final del ciclo ¡Felicitaciones! 🎉. Completa los siguientes campos para generar estadisticas.</Typography.Paragraph>
        <Divider />
        <Form layout="vertical" onFinish={guardar} form={form}>
            <Form.Item label="Cantidad de peces recolectados" name="cantidad_peses" rules={[{ required: true, message: 'Campo requerido' }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item label="Peso promedio (kg) de peces por cosecha" name="peso_promedio_peces" rules={[{ required: true, message: 'Campo requerido' }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item label="Margen utilidad ponderado %" name='margen_utilidad_ponderado' rules={[{ required: true, message: 'Campo requerido' }]} >
                <InputNumber />
            </Form.Item>
            <Form.Item label="Fecha de cierre" name='fecha_final' rules={[{ required: true, message: 'Campo requerido' }]} >
                <DatePicker />
            </Form.Item>
        </Form>
        <Divider />
        <Typography.Paragraph>Los ciclos cerrados no se pueden volver a modificar!</Typography.Paragraph>
    </Modal>
}

export default CicloCerrar